import PropTypes from 'prop-types';
import React, { memo, useMemo, lazy, useState, Suspense } from 'react';
import { useHistory } from 'react-router';
import cn from 'classnames';

import Badge from 'src/components/common/Badge';
import Tooltip from 'src/components/common/Tooltip';
import Localize from 'src/components/common/Localize';

import './index.scss';

const SettingsMenuItem = ({
  title,
  variant,
  iconName,
  type,
  onClick,
  badgeChildren,
  className,
  size,
  to,
  tooltip,
}) => {
  const history = useHistory();
  const containerClassName = useMemo(
    () => cn('settings-menu-item-container', className),
    [className]
  );
  const titleClassName = useMemo(
    () =>
      cn(
        'settings-menu-item-container__title',
        `settings-menu-item-container__title__${variant}`
      ),
    [variant]
  );
  const [loadTooltip, setLoadTooltip] = useState(false);

  // tooltips with the same id appear at the same time on speech therapist dashboard
  const tooltipHash = `${tooltip}${Math.random(0, 1000)}`;

  const handleClick = onClick || (() => history.push(to));

  const TooltipLazyComponent = lazy(() =>
    import('src/components/common/Tooltip')
  );

  return (
    <div
      className={containerClassName}
      onClick={handleClick}
      onKeyPress={handleClick}
      role="button"
      tabIndex={0}
    >
      {title && <div className={titleClassName}>{title}</div>}
      <Badge
        iconName={iconName}
        type={type}
        size={size}
        tooltip={tooltipHash}
        onMouseEnter={() => setLoadTooltip(true)}
      >
        {badgeChildren}
      </Badge>
      {tooltip && loadTooltip && (
        <Suspense fallback={<Tooltip id="0"> </Tooltip>}>
          <TooltipLazyComponent id={tooltipHash}>
            <Localize id={`tooltip.${tooltip}`} />
          </TooltipLazyComponent>
        </Suspense>
      )}
    </div>
  );
};

SettingsMenuItem.propTypes = {
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  badgeChildren: PropTypes.node,
  to: PropTypes.string,
  tooltip: PropTypes.string,
  variant: PropTypes.string,
};

SettingsMenuItem.defaultProps = {
  type: 'primary',
  size: 'regular',
  title: null,
  badgeChildren: null,
  className: '',
  iconName: null,
  onClick: null,
  to: '',
  tooltip: null,
  variant: 'regular',
};

export default memo(SettingsMenuItem);
