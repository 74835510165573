import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { useIntlMessages } from 'src/hooks';
import { Button } from 'src/components/common';

import './index.scss';

const PromoCodeInput = ({
  className,
  disabled,
  getPromoCodeDiscount,
  promoCode,
  setPromoCode,
  wizardState,
}) => {
  const messages = useIntlMessages();
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const inputClassNames = useMemo(
    () =>
      classNames(
        'promo-code-container__input-container__input',
        {
          'promo-code-input-container__input--disabled': disabled,
        },
        className
      ),
    [className, disabled]
  );

  useEffect(() => {
    if (promoCode?.length > 0) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }
  }, [promoCode]);

  return (
    <div className="promo-code-container">
      <div className="promo-code-container__input-container">
        <input
          className={inputClassNames}
          placeholder={messages.input.promoCode}
          disabled={disabled}
          value={promoCode}
          onChange={(event) => setPromoCode(event.target.value.trimEnd())}
        />
      </div>
      {!wizardState?.promoCode ? (
        <Button
          disabled={!buttonEnabled}
          collapsed
          size="medium"
          onClick={() => getPromoCodeDiscount(promoCode)}
        >
          {messages.button.apply}
        </Button>
      ) : (
        <Button
          disabled={!buttonEnabled}
          collapsed
          size="medium"
          onClick={() => setPromoCode('')}
        >
          {messages.button.remove}
        </Button>
      )}
    </div>
  );
};

PromoCodeInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  getPromoCodeDiscount: PropTypes.func.isRequired,
  promoCode: PropTypes.string.isRequired,
  setPromoCode: PropTypes.func.isRequired,
  wizardState: PropTypes.shape({
    promoCode: PropTypes.string,
  }).isRequired,
};

PromoCodeInput.defaultProps = {
  className: '',
  disabled: false,
};

export default memo(PromoCodeInput);
