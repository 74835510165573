import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Localize } from '@kokolingo/localisation';

import Modal from '../Modal/Modal';
import Button from '../Button';

import './index.scss';

const ModalPromo = ({ type, showModal, onShowModal, onClose, onCTAClick }) => {
  useEffect(() => {
    if (showModal) {
      onShowModal();
    }
  }, [showModal, onShowModal]);

  return (
    <Modal show={showModal} onClose={onClose} className="promo-modal">
      <Modal.Title>
        <Localize id={`dialog.promoTitle.${type}`} />
      </Modal.Title>
      <br />
      <Modal.Description>
        <Localize id={`dialog.promoDescription.${type}`} />
      </Modal.Description>
      <br />
      <Button variant="register" collapsed onClick={onCTAClick}>
        <Localize id={`dialog.promoCTA.${type}`} />
      </Button>
    </Modal>
  );
};

ModalPromo.propTypes = {
  type: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onShowModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCTAClick: PropTypes.func.isRequired,
};

export default ModalPromo;
