import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import { common } from 'src/utils';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  ContentContainer,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  PageLoader,
  SentenceCreator,
  Navbar,
  Zoom,
  LevelContent,
  Topbar,
} from 'src/components/common';

import './index.scss';
import AnswerCard from './components/AnswerCard';

const SHAKE_DELAY = 500;

const ReplenishmentContainer = ({
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  student,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();

  const [answers, setAnswers] = useState([]);
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [level, setLevel] = useState(0);
  const [sentence, setSentence] = useState([]);
  const [shakeAnswerImageUrl, setShakeAnswerImageUrl] = useState('');
  const [playAudio, isAudioPlaying] = useAudio();
  const [initialSentencePronounced, setInitialSentencePronounced] = useState(
    false
  );

  const pronounceInitialSentence = useCallback(() => {
    if (isAudioPlaying) {
      return;
    }

    setMotivationParrotVisibility(false);

    playAudio(items[level].initialSentenceAudioUrl, () =>
      setInitialSentencePronounced(true)
    );
  }, [isAudioPlaying, items, level, playAudio, setMotivationParrotVisibility]);

  const getSentenceImages = useCallback(
    () => ({
      imageUrl: items[level].sentenceImageUrl,
      key: items[level].sentenceWordKey,
    }),
    [level, items]
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      setInitialSentencePronounced(false);

      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (collectedCoins + 1 >= items.length && items.length > 0) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      if (level < items.length - 1) {
        setLevel((prevLevel) => prevLevel + 1);
      }

      handleLevelAnswer(items[level].id, isCorrect, currency?.id);

      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);
      setMotivationParrotVisibility(true);
    },
    [
      collectedCoins,
      currency,
      handleCollectionEnd,
      handleLevelAnswer,
      items,
      level,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  const checkAnswer = useCallback(
    (answerImageUrl) => {
      if (isAudioPlaying || !initialSentencePronounced) {
        return;
      }

      setMotivationParrotVisibility(false);

      if (answerImageUrl === items[level].correctImageUrl) {
        playAudio(feedback.HAPPY_SOUND_URL, () => {
          if (items[level].completeSentenceAudioUrl !== '') {
            playAudio(items[level].completeSentenceAudioUrl, () => {
              if (!displayModal) {
                setDisplayModal(true);
              }
            });
          } else if (!displayModal) {
            setDisplayModal(true);
          }
        });

        setSentence(
          <SentenceCreator
            correctImageUrls={[items[level].correctImageUrl]}
            imageClassNames="replenishment-sentence-image"
            initialSentence={items[level].sentence}
            isCorrect
            sentenceImages={[getSentenceImages()]}
            textClassNames="replenishment-sentence-text"
          />
        );
      } else {
        if (student.hasEnabledErrorSound) {
          playAudio(feedback.BAD_SOUND_URL);
        }

        handleLevelAnswer(items[level].id, false, currency?.id);

        setShakeAnswerImageUrl(answerImageUrl);

        setTimeout(() => {
          setShakeAnswerImageUrl('');
        }, SHAKE_DELAY);
      }
    },
    [
      isAudioPlaying,
      initialSentencePronounced,
      items,
      level,
      playAudio,
      getSentenceImages,
      displayModal,
      student.hasEnabledErrorSound,
      handleLevelAnswer,
      currency,
      setMotivationParrotVisibility,
    ]
  );

  useEffect(() => {
    if (hasLoaded) {
      setSentence(
        <SentenceCreator
          imageClassNames="replenishment-sentence-image"
          initialSentence={items[level].sentence}
          sentenceImages={[getSentenceImages()]}
          textClassNames="replenishment-sentence-text"
        />
      );
    }
  }, [hasLoaded, level, items, getSentenceImages]);

  useEffect(() => {
    if (hasLoaded) {
      let allAnswers = [
        items[level].firstDistractorImageUrl,
        items[level].secondDistractorImageUrl,
        items[level].correctImageUrl,
      ];

      allAnswers = common.shuffle(allAnswers);

      setAnswers(allAnswers);
    }
  }, [hasLoaded, level, items]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.replenishment}
        videoName={video.NAME.REPLENISHMENT}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className="replenishment-levels-container">
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(items[level].completeSentenceAudioUrl, null, false)
            }
          >
            <LevelContent.Sentence>{sentence}</LevelContent.Sentence>
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>
        <Zoom
          mobileWidth={320}
          mobileHeight={420}
          mobileRatio="calc(100% - 80px)"
        >
          <div className="replenishment-levels-container__body">
            <div className="replenishment-levels-container__body__answers-container">
              {answers.map((answer, index) => (
                <AnswerCard
                  key={index}
                  answer={answer}
                  shakeAnswerImageUrl={shakeAnswerImageUrl}
                  onClick={checkAnswer}
                  outline={!initialSentencePronounced}
                />
              ))}
            </div>
            <ContentContainer
              className="replenishment-levels-container__body__sentence-container"
              onClick={() => pronounceInitialSentence()}
            >
              <LevelContent.Sentence>{sentence}</LevelContent.Sentence>
            </ContentContainer>
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

ReplenishmentContainer.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      completeSentenceAudioUrl: PropTypes.string.isRequired,
      correctImageUrl: PropTypes.string.isRequired,
      firstDistractorImageUrl: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      initialSentenceAudioUrl: PropTypes.string.isRequired,
      secondDistractorImageUrl: PropTypes.string.isRequired,
      sentence: PropTypes.string.isRequired,
      sentenceImageUrl: PropTypes.string.isRequired,
      sentenceWordKey: PropTypes.string.isRequired,
    })
  ),
  navigation: PropTypes.node,
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

ReplenishmentContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(ReplenishmentContainer);
