import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _groupBy from 'lodash/groupBy';

import { useIntlMessages } from 'src/hooks';
import { actions, selectors } from 'src/store';
import { paths } from 'src/constants';
import config from 'src/config.json';

import {
  Badge,
  Modal,
  Page,
  PageLoader,
  Localize,
  Navbar,
  Button,
} from 'src/components/common';

import { SpeechSoundCard, SpeechSoundInfo } from './components';

import './index.scss';

const SpeechSoundSelection = ({
  getSpeechSounds,
  hasLoaded,
  isAuthenticationLoading,
  isSpeechSoundLoading,
  student,
  studentSpeechSounds,
  updateStudentById,
  user,
  isSpeechTherapist,
  resetDailyMap,
  resetActivityGroupsState,
  userHasActiveSubscription,
}) => {
  const messages = useIntlMessages();
  const history = useHistory();

  const [selectedSpeechSoundId, setSelectedSpeechSoundId] = useState(
    student.SpeechSoundId
  );

  const modalClose = useCallback(
    () =>
      updateStudentById(student.id, {
        hasPassedSpeechSoundSelectionEducation: true,
      }),
    [student.id, updateStudentById]
  );

  const handleSelect = useCallback(
    (speechSoundId) => {
      const selectedSpeechSound =
        student.SpeechSoundId === speechSoundId ? null : speechSoundId;
      updateStudentById(student.id, {
        SpeechSoundId: selectedSpeechSound,
      }).then(() => {
        if (process.env.REACT_APP_DAILY_MAP === 'true') {
          resetDailyMap();
        }
      });
      setSelectedSpeechSoundId(speechSoundId);
      resetActivityGroupsState();
    },
    [updateStudentById, student, resetDailyMap, resetActivityGroupsState]
  );

  useEffect(() => {
    getSpeechSounds();
  }, [getSpeechSounds]);

  const groupedSounds = _groupBy(
    studentSpeechSounds.filter(({ baseSound }) => !!baseSound),
    ({ baseSound }) => baseSound || 'baseSounds'
  );

  const baseSounds = studentSpeechSounds.filter(({ baseSound }) => !baseSound);

  const content = (
    <>
      <Navbar.Actions>
        <Navbar.EducationAction videos={config.videos.speechSoundEducation} />
      </Navbar.Actions>
      {!isSpeechTherapist && (
        <Modal
          show={!student.hasPassedSpeechSoundSelectionEducation}
          onOutsideClick={modalClose}
        >
          <Modal.Description>
            <Localize
              id={
                // eslint-disable-next-line no-nested-ternary
                userHasActiveSubscription
                  ? 'page.soundSelection.luckyNote3'
                  : user.freeTrialMaxPoints > 0
                  ? 'page.soundSelection.luckyNote'
                  : 'page.soundSelection.luckyNote2'
              }
              values={{
                freeTrialMaxPoints: user.freeTrialMaxPoints,
              }}
            />
          </Modal.Description>
          <div className="modal-container__buttons">
            <Badge
              img="actor"
              size="large"
              iconSize={85}
              onClick={modalClose}
            />
          </div>
        </Modal>
      )}
      <Page className="speech-sounds-page">
        <Page.Section>
          <Page.Body
            color="light"
            size="md"
            className="speech-sounds-page__body"
          >
            <div className="speech-sounds-page__container__sounds">
              <p className="speech-sounds-page__sounds__title">
                <Localize id="page.soundSelection.selectSounds" />
              </p>
              <div className="speech-sounds-page__sounds__container">
                {baseSounds.map((speechSound, index) => (
                  <SpeechSoundCard
                    key={index}
                    speechSound={speechSound}
                    isSelected={student.SpeechSoundId === speechSound.id}
                    onClick={() => handleSelect(speechSound.id)}
                  />
                ))}
              </div>
              {Object.keys(groupedSounds).map((baseSound) => (
                <>
                  <div className="speech-sounds-page__sounds__container__cluster__title">
                    <Localize
                      id="page.soundSelection.blends"
                      values={{ sound: baseSound }}
                    />
                  </div>
                  <div className="speech-sounds-page__sounds__container speech-sounds-page__sounds__container__cluster">
                    {groupedSounds[baseSound].map((speechSound, index) => (
                      <SpeechSoundCard
                        isCluster
                        key={index}
                        speechSound={speechSound}
                        isSelected={student.SpeechSoundId === speechSound.id}
                        onClick={() => handleSelect(speechSound.id)}
                      />
                    ))}
                  </div>
                </>
              ))}
            </div>
            <div className="speech-sounds-page__button__container">
              <Button
                className="speech-sounds-page__button"
                variant="register"
                disabled={!student.SpeechSoundId}
                label={messages.button.startAdventure}
                onClick={() =>
                  history.push(paths.buildQuery(paths.MAP, { userId: user.id }))
                }
              />
            </div>
          </Page.Body>
          <Page.Body className="speech-sounds-page__sound__information">
            <SpeechSoundInfo
              autoPlay={student.hasPassedSpeechSoundSelectionEducation}
              isLoading={isAuthenticationLoading && isSpeechSoundLoading}
              selectedSpeechSoundId={selectedSpeechSoundId}
            />
          </Page.Body>
        </Page.Section>
      </Page>
    </>
  );

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return content;
};

SpeechSoundSelection.propTypes = {
  getSpeechSounds: PropTypes.func.isRequired,
  updateStudentById: PropTypes.func.isRequired,
  isSpeechSoundLoading: PropTypes.bool.isRequired,
  isAuthenticationLoading: PropTypes.bool.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  studentSpeechSounds: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.number,
      isSelected: PropTypes.bool,
    })
  ).isRequired,
  student: PropTypes.shape({
    SpeechSoundId: PropTypes.number,
    hasPassedSpeechSoundSelectionEducation: PropTypes.bool,
    id: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isSpeechTherapist: PropTypes.bool.isRequired,
  resetDailyMap: PropTypes.func.isRequired,
  resetActivityGroupsState: PropTypes.func.isRequired,
  userHasActiveSubscription: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  hasLoaded: selectors.speechSounds.getHasLoaded(state),
  isSpeechSoundLoading: selectors.speechSounds.getIsLoading(state),
  isAuthenticationLoading: selectors.authentication.getIsLoading(state),
  student: selectors.authentication.getStudent(state),
  studentSpeechSounds: selectors.speechSounds.getSpeechSoundsData(state),
  user: selectors.authentication.getUser(state),
  isSpeechTherapist: selectors.authentication.getIsSpeechTherapist(state),
  userHasActiveSubscription: selectors.subscriptionStatus.getSubscriptionStatus(
    state
  )?.isSubscription,
});

const mapDispatchToProps = {
  ...actions.activityGroups,
  ...actions.speechSounds,
  ...actions.students,
  ...actions.dailyMap,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SpeechSoundSelection);
