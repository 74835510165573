import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import { common } from 'src/utils';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  PageLoader,
  Navbar,
  Zoom,
  LevelContent,
  Topbar,
} from 'src/components/common';

import './index.scss';
import LevelCard from './components/LevelCard';

const SHAKE_DURATION = 500;

const PaintingContainer = ({
  groupOrderNumber,
  hasLoaded,
  items,
  student,
  currency,
  handleLevelAnswer,
  handleCollectionEnd,
  navigation,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();

  const [collectedCoins, setCollectedCoins] = useState(0);
  const [paintedTerms, setPaintedTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState({});
  const [shakeLeft, setShakeLeft] = useState(false);
  const [shakeRight, setShakeRight] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [playAudio, isAudioPlaying] = useAudio();

  const selectTerm = useCallback(
    (term) => {
      if (isAudioPlaying) {
        return;
      }

      if (term.audioUrl !== '') {
        playAudio(term.audioUrl, () => {
          setDisplayModal(true);
        });
      }

      setMotivationParrotVisibility(false);
      setSelectedTerm(term);
    },
    [isAudioPlaying, playAudio, setMotivationParrotVisibility]
  );

  const levelItems = useMemo(() => common.shuffle(items), [items]);

  const handleAnswer = useCallback(
    (answer, isLeft) => {
      if (!selectedTerm.id || isAudioPlaying) {
        return;
      }

      if (selectedTerm.correctSpeechSound !== answer) {
        if (student.hasEnabledErrorSound) {
          playAudio(feedback.BAD_SOUND_URL);
        }

        setShakeLeft(isLeft);
        setShakeRight(!isLeft);
        setTimeout(() => {
          setShakeLeft(false);
          setShakeRight(false);
        }, SHAKE_DURATION);

        handleLevelAnswer(selectedTerm.id, false, currency?.id);
      } else {
        playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
          setPaintedTerms([
            ...paintedTerms,
            {
              selectedTermId: selectedTerm.id,
              paint: isLeft ? 'red' : 'green',
            },
          ]);
          handleLevelAnswer(selectedTerm.id, true, currency?.id);
          const repeatingCorrectAnswer = paintedTerms.some(
            (term) => term.selectedTermId === selectedTerm.id
          );
          if (!repeatingCorrectAnswer) {
            setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
          }
          setDisplayModal(false);
          setMotivationParrotVisibility(true);
        });
      }
    },
    [
      currency,
      handleLevelAnswer,
      isAudioPlaying,
      paintedTerms,
      playAudio,
      selectedTerm.correctSpeechSound,
      selectedTerm.id,
      student.hasEnabledErrorSound,
      setMotivationParrotVisibility,
    ]
  );

  useEffect(() => {
    if (collectedCoins >= levelItems.length && levelItems.length > 0) {
      playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
    }
    // playAudio in deps causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedCoins, handleCollectionEnd, levelItems.length, paintedTerms]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={`${levelItems[0].leftSpeechSound}${
          levelItems[0].rightSpeechSound !== ''
            ? `-${levelItems[0].rightSpeechSound}`
            : ''
        }`}
        videoName={video.NAME.PAINTING}
        videoLabel={messages.subactivity.painting}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={levelItems.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container
        className={`painting-levels-container painting-levels-container--background-${groupOrderNumber}`}
      >
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(selectedTerm.audioUrl, null, false)
            }
          >
            <LevelContent.Image
              alt={selectedTerm.key}
              src={selectedTerm.imageUrl}
            />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Sounds
            onClick={handleAnswer}
            selectedTerm={selectedTerm}
            shakeLeft={shakeLeft}
            shakeRight={shakeRight}
          />
        </FeedbackContainer>

        <Zoom
          mobileWidth={320}
          mobileHeight={420}
          mobileRatio="calc(100% - 120px)"
          align="center"
        >
          <div className="painting-levels-container__body">
            {levelItems.map((level) => (
              <LevelCard
                key={level.id}
                level={level}
                paintedTerms={paintedTerms}
                selectedTermId={selectedTerm.id}
                onClick={selectTerm}
              />
            ))}
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

PaintingContainer.propTypes = {
  groupOrderNumber: PropTypes.number,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string.isRequired,
      correctSpeechSound: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      leftSpeechSound: PropTypes.string.isRequired,
      rightSpeechSound: PropTypes.string.isRequired,
    })
  ),
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleLevelAnswer: PropTypes.func.isRequired,
  handleCollectionEnd: PropTypes.func.isRequired,
  navigation: PropTypes.node,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

PaintingContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
  groupOrderNumber: 0,
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(PaintingContainer);
