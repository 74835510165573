import { actions } from 'src/constants';

const initialState = {
  data: {},
  isSubmitting: false,
  hasLoaded: false,
  motivationalAudioUrl: '',
  isParrotVisible: true,
};

const actionMap = {
  [actions.FEEDBACK_UPDATE_REQUEST]: (state) => ({
    ...state,
    isSubmitting: true,
  }),
  [actions.FEEDBACK_UPDATE_SUCCESS]: (state, { result }) => ({
    ...state,
    motivationalAudioUrl: result.data.motivationalAudioUrl,
    isSubmitting: false,
  }),
  [actions.FEEDBACK_UPDATE_FAILURE]: (state) => ({
    ...state,
    isSubmitting: false,
  }),

  [actions.FEEDBACK_STATISTICS_GET_REQUEST]: (state) => ({
    ...state,
    hasLoaded: false,
  }),
  [actions.FEEDBACK_STATISTICS_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
  }),
  [actions.FEEDBACK_STATISTICS_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: true,
  }),
  [actions.PARROT_MOTIVATION_HIDE]: (state) => ({
    ...state,
    motivationalAudioUrl: '',
  }),
  [actions.PARROT_MOTIVATION_VISIBILITY]: (state, { payload }) => ({
    ...state,
    isParrotVisible: payload,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
