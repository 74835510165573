import React, { memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useAudio } from 'src/hooks';
import { media } from 'src/utils';
import { selectors } from 'src/store';

import SpeechSound from '../SpeechSound';

import '../../index.scss';
import Localize from '../../../Localize';
import Page from '../../../Page';
import Icon from '../../../Icon';
import Badge from '../../../Badge';

const AudioSoundFeedback = ({
  onClick,
  label,
  selectedTerm,
  shakeLeft,
  shakeRight,
  speechSound,
}) => {
  const [playAudio] = useAudio();
  const playSpeechSound = () => {
    const soundUrl = media.getSpeechSoundAudioUrl(
      speechSound.label.toLowerCase()
    );
    playAudio(soundUrl);
  };

  return (
    <Page.Body className="feedback-panel-container" size="md">
      <div className="feedback-panel-container__label">
        {label}
        {speechSound && (
          <Badge iconName="sound" size="small" onClick={playSpeechSound} />
        )}
      </div>
      <div className="feedback-panel-container__feedback">
        <SpeechSound
          isLeft
          onClick={onClick}
          shake={shakeLeft}
          speechSound={selectedTerm.leftSpeechSound}
          mark={<Icon name="check" />}
          type="success"
        />
        <SpeechSound
          mark={<Icon name="close" />}
          onClick={onClick}
          shake={shakeRight}
          speechSound={selectedTerm.rightSpeechSound}
          type="danger"
        />
      </div>
    </Page.Body>
  );
};

AudioSoundFeedback.propTypes = {
  label: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  selectedTerm: PropTypes.shape({
    leftSpeechSound: PropTypes.string.isRequired,
    rightSpeechSound: PropTypes.string.isRequired,
    correctSpeechSound: PropTypes.string.isRequired,
  }).isRequired,
  shakeLeft: PropTypes.bool.isRequired,
  shakeRight: PropTypes.bool.isRequired,
  speechSound: PropTypes.string.isRequired,
};

AudioSoundFeedback.defaultProps = {
  label: <Localize id="label.didWeHearCorrectly" />,
};

const mapStateToProps = (state) => ({
  speechSound: selectors.speechSounds.getSpeechSound(state),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(AudioSoundFeedback);
