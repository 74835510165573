import { actions, paths } from 'src/constants';

export default {
  authenticateUser: () => ({
    [actions.API_CALL]: {
      types: [
        actions.AUTHENTICATE_USER_REQUEST,
        actions.AUTHENTICATE_USER_SUCCESS,
        actions.AUTHENTICATE_USER_FAILURE,
      ],
      promise: (client) => client.get(paths.api.AUTHENTICATE_HEALTH),
    },
  }),

  resolveUsers: () => ({
    [actions.API_CALL]: {
      types: [
        actions.RESOLVE_USERS_REQUEST,
        actions.RESOLVE_USERS_SUCCESS,
        actions.RESOLVE_USERS_FAILURE,
      ],
      promise: (client) => client.get(paths.api.AUTHENTICATE_RESOLVE_USERS),
    },
  }),

  registerClient: (data, inviteToken) => ({
    [actions.API_CALL]: {
      types: [
        actions.REGISTER_CLIENT_REQUEST,
        actions.REGISTER_CLIENT_SUCCESS,
        actions.REGISTER_CLIENT_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.AUTHENTICATE_REGISTER_CLIENT, data, {
          params: { inviteToken },
        }),
    },
  }),

  registerSpeechTherapist: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.REGISTER_SPEECH_THERAPIST_REQUEST,
        actions.REGISTER_SPEECH_THERAPIST_SUCCESS,
        actions.REGISTER_SPEECH_THERAPIST_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.AUTHENTICATE_REGISTER_SPEECH_THERAPIST, data),
    },
  }),

  requestResetPasswordLink: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.FORGOT_PASSWORD_REQUEST,
        actions.FORGOT_PASSWORD_SUCCESS,
        actions.FORGOT_PASSWORD_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.AUTHENTICATE_FORGOT_PASSWORD, data),
    },
  }),

  resetPassword: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.RESET_PASSWORD_REQUEST,
        actions.RESET_PASSWORD_SUCCESS,
        actions.RESET_PASSWORD_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.AUTHENTICATE_RESET_PASSWORD, data),
    },
  }),

  login: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.LOGIN_REQUEST,
        actions.LOGIN_SUCCESS,
        actions.LOGIN_FAILURE,
      ],
      promise: (client) => client.post(paths.api.AUTHENTICATE_LOGIN, data),
    },
  }),

  facebookLogin: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.FACEBOOK_LOGIN_REQUEST,
        actions.FACEBOOK_LOGIN_SUCCESS,
        actions.FACEBOOK_LOGIN_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.AUTHENTICATE_FACEBOOK_LOGIN, data),
    },
  }),

  googleLogin: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.GOOGLE_LOGIN_REQUEST,
        actions.GOOGLE_LOGIN_SUCCESS,
        actions.GOOGLE_LOGIN_FAILURE,
      ],
      promise: (client) =>
        client.post(paths.api.AUTHENTICATE_GOOGLE_LOGIN, data),
    },
  }),

  updateAuthUser: (data) => ({
    [actions.API_CALL]: {
      types: [
        actions.USER_UPDATE_REQUEST,
        actions.USER_UPDATE_SUCCESS,
        actions.USER_UPDATE_FAILURE,
      ],
      promise: (client) => client.patch(paths.api.USERS_ME, data),
    },
  }),

  dismissUpdateNotificationAlert: () => ({
    [actions.API_CALL]: {
      types: [
        actions.DISMISS_UPDATE_NOTIFICATION_ALERT_REQUEST,
        actions.DISMISS_UPDATE_NOTIFICATION_ALERT_SUCCESS,
        actions.DISMISS_UPDATE_NOTIFICATION_ALERT_FAILURE,
      ],
      promise: (client) => client.post(paths.api.AUTHENTICATE_UPDATE_INFO),
    },
  }),

  setStudentSpeechSound: (speechSoundId) => ({
    type: actions.SET_STUDENT_SPEECH_SOUND,
    speechSoundId,
  }),

  resetState: () => ({
    type: actions.STATE_RESET,
  }),
};
