import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import { common } from 'src/utils';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  PageLoader,
  Zoom,
  Navbar,
  Card,
  LevelContent,
  Topbar,
  Image,
} from 'src/components/common';

import TreasureChest from './components/TreasureChest';

import './index.scss';

const SHAKE_DURATION = 500;

const TreasureHuntContainer = ({
  currency,
  groupOrderNumber,
  handleLevelAnswer,
  handleCollectionEnd,
  hasLoaded,
  items,
  navigation,
  student,
  setMotivationParrotVisibility,
}) => {
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [collectedSoundsLeft, setCollectedSoundsLeft] = useState([]);
  const [collectedSoundsRight, setCollectedSoundsRight] = useState([]);
  const [level, setLevel] = useState(0);
  const [shakeLeft, setShakeLeft] = useState(false);
  const [shakeRight, setShakeRight] = useState(false);
  const [isTermSelected, setIsTermSelected] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [playAudio, isAudioPlaying] = useAudio();

  const messages = useIntlMessages();

  const levelItems = useMemo(() => common.shuffle(items), [items]);

  const pronounceTerm = useCallback(
    (url) => {
      if (isAudioPlaying) {
        return;
      }

      playAudio(url, () => {
        setIsTermSelected(true);
        setDisplayModal(true);
        setMotivationParrotVisibility(false);
      });
    },
    [isAudioPlaying, playAudio, setMotivationParrotVisibility]
  );

  const handleAnswer = useCallback(
    (answer, isLeft) => {
      if (isAudioPlaying || !isTermSelected) {
        return;
      }

      const correctAnswer = levelItems[level].correctSpeechSound;
      if (correctAnswer !== answer) {
        if (student.hasEnabledErrorSound) {
          playAudio(feedback.BAD_SOUND_URL);
        }

        setShakeLeft(isLeft);
        setShakeRight(!isLeft);
        setTimeout(() => {
          setShakeLeft(false);
          setShakeRight(false);
        }, SHAKE_DURATION);

        handleLevelAnswer(levelItems[level].id, false, currency?.id);
      } else {
        playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
          setIsTermSelected(false);
          setDisplayModal(false);
          if (level < levelItems.length - 1) {
            setLevel((prevLevel) => prevLevel + 1);
          }

          handleLevelAnswer(levelItems[level].id, true, currency?.id);
          setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
          setMotivationParrotVisibility(true);

          if (isLeft) {
            setCollectedSoundsLeft((state) => [...state, levelItems[level]]);
          } else {
            setCollectedSoundsRight((state) => [...state, levelItems[level]]);
          }
        });
      }
    },
    [
      isAudioPlaying,
      isTermSelected,
      levelItems,
      level,
      student.hasEnabledErrorSound,
      handleLevelAnswer,
      currency,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  useEffect(() => {
    if (collectedCoins >= levelItems.length && levelItems.length > 0) {
      playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
    }
    // playAudio in deps causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedCoins, handleCollectionEnd, levelItems.length]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={`${levelItems[0].leftSpeechSound}${
          levelItems[0].rightSpeechSound !== ''
            ? `-${levelItems[0].rightSpeechSound}`
            : ''
        }`}
        videoLabel={messages.subactivity.treasureHunt}
        videoName={video.NAME.TREASURE_HUNT}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={levelItems.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container
        className={`treasure-hunt-levels-container treasure-hunt-levels-container--background-${groupOrderNumber}`}
      >
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(levelItems[level].audioUrl, null, false)
            }
          >
            <LevelContent.Image
              alt={levelItems[level].key}
              src={levelItems[level].imageUrl}
            />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Sounds
            onClick={handleAnswer}
            selectedTerm={levelItems[level]}
            shakeLeft={shakeLeft}
            shakeRight={shakeRight}
          />
        </FeedbackContainer>
        <Zoom
          mobileWidth={320}
          mobileHeight={478}
          mobileRatio="calc(100% - 100px)"
        >
          <div className="treasure-hunt-levels-container__body">
            <TreasureChest
              collectedSounds={collectedSoundsLeft}
              isLeft
              speechSound={levelItems[0].leftSpeechSound}
            />
            <TreasureChest
              collectedSounds={collectedSoundsRight}
              speechSound={levelItems[0].rightSpeechSound}
            />
            <Card
              className="treasure-hunt-levels-container__body__card"
              onClick={() => pronounceTerm(levelItems[level].audioUrl)}
            >
              <Image
                alt={levelItems[level].key}
                src={levelItems[level].imageUrl}
              />
            </Card>
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

TreasureHuntContainer.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  groupOrderNumber: PropTypes.number,
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string.isRequired,
      correctSpeechSound: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      imageUrl: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      leftSpeechSound: PropTypes.string.isRequired,
      rightSpeechSound: PropTypes.string.isRequired,
    })
  ),
  navigation: PropTypes.node,
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

TreasureHuntContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
  groupOrderNumber: 0,
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(TreasureHuntContainer);
