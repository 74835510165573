import { createSelector } from 'reselect';

const getIsSubmitting = createSelector(
  (state) => state.promoCodes.isSubmitting,
  (isSubmitting) => isSubmitting
);

const getHasLoaded = createSelector(
  (state) => state.promoCodes.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getPromoCodeDiscount = createSelector(
  (state) => state.promoCodes.data,
  (promoCodeDiscount = null) => promoCodeDiscount
);

export default {
  getPromoCodeDiscount,
  getHasLoaded,
  getIsSubmitting,
};
