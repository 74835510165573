import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  PageLoader,
  Navbar,
  Zoom,
  LevelContent,
  Topbar,
} from 'src/components/common';

import './index.scss';
import LevelCard from './components/LevelCard';

const TIMEOUT_DURATION = 500;

const MemoryContainer = ({
  cardClassName,
  className,
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  student,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();

  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isLevelSectionClickable, setIsLevelSectionClickable] = useState(true);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [solvedTermKeys, setSolvedTermKeys] = useState([]);
  const [playAudio] = useAudio();

  const selectTerm = useCallback(
    (level, index) => {
      if (!selectedTerms.some((term) => term.index === index)) {
        setSelectedTerms([...selectedTerms, { level, index }]);
      }
    },
    [selectedTerms]
  );

  const levelContainerClassNames = useMemo(
    () =>
      cn(`memory-levels-container__body--${items.length}`, {
        'memory-levels-container__body--unclickable': !isLevelSectionClickable,
      }),
    [items, isLevelSectionClickable]
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (collectedCoins + 2 >= items.length && items.length > 0) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      handleLevelAnswer(selectedTerms[0].level.id, isCorrect, currency?.id);
      setSolvedTermKeys((termKeys) => [
        ...termKeys,
        selectedTerms[0].level.key,
      ]);
      setIsLevelSectionClickable(true);
      setSelectedTerms([]);
      setCollectedCoins(collectedCoins + 2);
      setDisplayModal(false);
      setMotivationParrotVisibility(true);
    },
    [
      collectedCoins,
      currency,
      handleCollectionEnd,
      handleLevelAnswer,
      items.length,
      playAudio,
      selectedTerms,
      setMotivationParrotVisibility,
    ]
  );

  const handleSelectedTerms = useCallback(() => {
    setIsLevelSectionClickable(false);
    setMotivationParrotVisibility(false);

    if (selectedTerms[0].level.key === selectedTerms[1].level.key) {
      playAudio(feedback.HAPPY_SOUND_URL, () => {
        if (selectedTerms[1].level.audioUrl !== '') {
          playAudio(selectedTerms[1].level.audioUrl, () =>
            setDisplayModal(true)
          );
        } else {
          setDisplayModal(true);
        }
      });
    } else {
      if (student.hasEnabledErrorSound) {
        playAudio(feedback.BAD_SOUND_URL, () => {
          setTimeout(() => {
            handleLevelAnswer(selectedTerms[0].level.id, false, currency?.id);
            setIsLevelSectionClickable(true);
            setSelectedTerms([]);
          }, TIMEOUT_DURATION);
        });
        return;
      }

      setTimeout(() => {
        handleLevelAnswer(selectedTerms[0].level.id, false, currency?.id);
        setIsLevelSectionClickable(true);
        setSelectedTerms([]);
      }, TIMEOUT_DURATION);
    }
  }, [
    currency,
    handleLevelAnswer,
    playAudio,
    selectedTerms,
    student.hasEnabledErrorSound,
    setMotivationParrotVisibility,
  ]);

  useEffect(() => {
    if (selectedTerms.length === 2) {
      handleSelectedTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTerms]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.memory}
        videoName={video.NAME.MEMORY}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className={cn('memory-levels-container', className)}>
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(selectedTerms[0].level.audioUrl, null, false)
            }
          >
            <LevelContent.Text
              label={selectedTerms[0] && selectedTerms[0].level.key}
              size="small"
            />
            <LevelContent.Image
              alt={selectedTerms[0] && selectedTerms[0].level.key}
              src={selectedTerms[0] && selectedTerms[0].level.imageUrl}
            />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>

        <Zoom
          mobileWidth={320}
          mobileHeight={530}
          mobileRatio="calc(100% - 80px)"
        >
          <div className="memory-levels-container__body">
            <div className={levelContainerClassNames}>
              {items.map((level, index) => (
                <LevelCard
                  className={cardClassName}
                  key={index}
                  index={index}
                  level={level}
                  selectedTerms={selectedTerms}
                  solvedTermKeys={solvedTermKeys}
                  onClick={selectTerm}
                  type={items.length}
                />
              ))}
            </div>
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

MemoryContainer.propTypes = {
  cardClassName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  navigation: PropTypes.node,
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

MemoryContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(MemoryContainer);
