import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import { common } from 'src/utils';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  ContentContainer,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  PageLoader,
  SentenceCreator,
  Navbar,
  Zoom,
  LevelContent,
  Topbar,
} from 'src/components/common';

import FortuneWheel from './components/FortuneWheel';

import './index.scss';

const INITIAL_TERM_POSITIONS = ['one-third', 'two-thirds', 'three-thirds'];
const SPIN_DELAY = 3000;
const WHEEL_OF_FORTUNE_PIES = 3;

const getSentenceImages = (currentLevel) => ({
  imageUrl: currentLevel.sentenceImageUrl,
  key: currentLevel.sentenceWordKey,
});

const WheelOfFortuneContainer = ({
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();
  const [answers, setAnswers] = useState([]);
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [isWheelSpinning, setIsWheelSpinning] = useState(false);
  const [level, setLevel] = useState(0);
  const [spinResultItem, setSpinResultItem] = useState(null);
  const [animationPosition, setAnimationPosition] = useState(null);
  const [playAudio, isAudioPlaying] = useAudio();
  const currentLevel = items[level];

  const playCurrentSentence = useCallback(
    (spinResult) => {
      if (isWheelSpinning || isAudioPlaying) {
        return;
      }

      if (!spinResult) {
        playAudio(currentLevel.initialSentenceAudioUrl);
        return;
      }

      if (spinResult.audioUrl !== '') {
        playAudio(spinResult.audioUrl, () => {
          const resultIndex = answers.findIndex(
            (answer) => answer.imageUrl === spinResult.imageUrl
          );

          const newAnswers = answers.filter(
            (answer, index) => index !== resultIndex
          );

          setAnswers(newAnswers);

          setDisplayModal(true);
          setMotivationParrotVisibility(false);
        });
      } else {
        setAnswers(
          answers.filter((answer) => answer.imageUrl !== spinResult.imageUrl)
        );

        if (!displayModal) {
          setDisplayModal(true);
          setMotivationParrotVisibility(false);
        }
      }
    },
    [
      answers,
      currentLevel,
      displayModal,
      isAudioPlaying,
      isWheelSpinning,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  const setAnswersPositions = useCallback(
    (newAnswers) =>
      newAnswers.map((answer, index) => {
        return { ...answer, position: INITIAL_TERM_POSITIONS[index] };
      }),
    []
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (
          collectedCoins + 1 >= items.length * WHEEL_OF_FORTUNE_PIES &&
          items.length > 0
        ) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      if (
        answers.length < 1 &&
        collectedCoins + 1 < items.length * WHEEL_OF_FORTUNE_PIES
      ) {
        setLevel((prevLevel) => prevLevel + 1);
      }

      handleLevelAnswer(items[level].id, isCorrect, currency?.id);

      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);
      setSpinResultItem(null);
      setMotivationParrotVisibility(true);
    },
    [
      answers.length,
      collectedCoins,
      currency,
      handleCollectionEnd,
      handleLevelAnswer,
      items,
      level,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  const spinTheWheel = useCallback(() => {
    if (isAudioPlaying || displayModal) {
      return;
    }

    setIsWheelSpinning(true);
    setMotivationParrotVisibility(false);

    const spinResult = answers[Math.floor(Math.random() * answers.length)];
    setAnimationPosition(spinResult.position);

    setTimeout(() => {
      setIsWheelSpinning(false);
      setSpinResultItem(spinResult);
      playAudio(feedback.HAPPY_SOUND_URL, () =>
        playCurrentSentence(spinResult)
      );
    }, SPIN_DELAY);
  }, [
    isAudioPlaying,
    displayModal,
    answers,
    playAudio,
    playCurrentSentence,
    setMotivationParrotVisibility,
  ]);

  useEffect(() => {
    if (hasLoaded) {
      let allAnswers = [
        {
          imageUrl: items[level].firstImageUrl,
          audioUrl: items[level].firstCompleteSentenceAudioUrl,
        },
        {
          imageUrl: items[level].secondImageUrl,
          audioUrl: items[level].secondCompleteSentenceAudioUrl,
        },
        {
          imageUrl: items[level].thirdImageUrl,
          audioUrl: items[level].thirdCompleteSentenceAudioUrl,
        },
      ];

      allAnswers = common.shuffle(allAnswers);

      setSpinResultItem(null);
      setAnswers(setAnswersPositions(allAnswers));
    }
  }, [hasLoaded, level, items, setAnswersPositions]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  const sentence = (
    <WheelOfFortuneSentenceCreator
      currentLevel={currentLevel}
      spinItem={spinResultItem}
    />
  );

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.wheelOfFortune}
        videoName={video.NAME.WHEEL_OF_FORTUNE}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length * WHEEL_OF_FORTUNE_PIES}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className="wheel-of-fortune-levels-container">
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(spinResultItem.audioUrl, null, false)
            }
          >
            <LevelContent.Sentence>{sentence}</LevelContent.Sentence>
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>

        <Zoom
          mobileWidth={320}
          mobileHeight={510}
          mobileRatio="calc(100% - 80px)"
          align="center"
        >
          <div className="wheel-of-fortune-levels-container__body">
            <FortuneWheel
              answers={answers}
              isWheelSpinning={isWheelSpinning}
              onClick={spinTheWheel}
              spinWheelAnimation={animationPosition}
            />
            <ContentContainer
              className="wheel-of-fortune-levels-container__body__sentence-container"
              onClick={() => playCurrentSentence(spinResultItem)}
            >
              <LevelContent.Sentence>{sentence}</LevelContent.Sentence>
            </ContentContainer>
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

const WheelOfFortuneSentenceCreator = ({ currentLevel, spinItem }) => {
  return (
    <SentenceCreator
      correctImageUrls={[spinItem?.imageUrl]}
      imageClassNames="wheel-of-fortune-sentence-image"
      initialSentence={currentLevel.sentence}
      isCorrect={!!spinItem}
      sentenceImages={[getSentenceImages(currentLevel)]}
      textClassNames="wheel-of-fortune-sentence-text"
    />
  );
};
WheelOfFortuneSentenceCreator.propTypes = {
  currentLevel: PropTypes.shape({
    id: PropTypes.number,
    sentence: PropTypes.string,
    sentenceImageUrl: PropTypes.string,
    sentenceWordKey: PropTypes.string,
  }).isRequired,
  spinItem: PropTypes.shape({
    position: PropTypes.string,
    imageUrl: PropTypes.string,
    audioUrl: PropTypes.string,
  }),
};

WheelOfFortuneSentenceCreator.defaultProps = {
  spinItem: null,
};

WheelOfFortuneContainer.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      firstCompleteSentenceAudioUrl: PropTypes.string.isRequired,
      firstImageUrl: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      initialSentenceAudioUrl: PropTypes.string.isRequired,
      secondCompleteSentenceAudioUrl: PropTypes.string.isRequired,
      secondImageUrl: PropTypes.string.isRequired,
      sentence: PropTypes.string.isRequired,
      sentenceImageUrl: PropTypes.string.isRequired,
      sentenceWordKey: PropTypes.string.isRequired,
      thirdCompleteSentenceAudioUrl: PropTypes.string.isRequired,
      thirdImageUrl: PropTypes.string.isRequired,
    })
  ),
  navigation: PropTypes.node,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

WheelOfFortuneContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(WheelOfFortuneContainer);
