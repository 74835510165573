import { actions } from 'src/constants';

const initialState = {
  data: null,
  hasLoaded: false,
  isSubmitting: false,
};

const actionMap = {
  [actions.PROMO_CODE_GET_REQUEST]: (state) => ({
    ...state,
    data: initialState.data,
    hasLoaded: false,
  }),
  [actions.PROMO_CODE_GET_SUCCESS]: (state, { result }) => ({
    ...state,
    data: result.data,
    hasLoaded: true,
  }),
  [actions.PROMO_CODE_GET_FAILURE]: (state) => ({
    ...state,
    hasLoaded: false,
  }),

  [actions.PROMO_CODE_CLEAR]: (state) => ({
    ...state,
    data: null,
  }),
};

export default (state = initialState, action) => {
  if (actionMap[action.type]) {
    return actionMap[action.type](state, action);
  }

  return state;
};
