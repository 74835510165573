import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { actions, selectors } from 'src/store';
import { useQuery, useIntlMessages } from 'src/hooks';
import { jwt } from 'src/utils';

import {
  GoogleLogin,
  LogoTitle,
  OverlayLoader,
  Page,
} from 'src/components/common';

import { FormContainer } from './components';

import './index.scss';

const Login = ({ login, isLoading }) => {
  const query = useQuery();
  const messages = useIntlMessages();
  const token = query.get('token');
  const redirect = query.get('redirect');

  if (token) {
    jwt.setToken(token);
    if (redirect) {
      window.location.replace(redirect);
    } else {
      window.location.reload();
    }
  }

  const content = useMemo(
    () =>
      !token && (
        <Page className="login-page" noNavigation>
          <Page.Section>
            {isLoading && <OverlayLoader />}
            {!isMobile && (
              <Page.Body color="transparent" noPadding>
                <LogoTitle />
              </Page.Body>
            )}
            <Page.Body size="sm" color="light">
              <FormContainer login={login} redirect={redirect} />
              <div className="signin-with">{messages.socials.login}</div>
              <div className="socials">
                {process.env.REACT_APP_GOOGLE_CLIENT_ID && <GoogleLogin />}
              </div>

              <div className="login-form-container__footer">
                <div
                  className="login-form-container__footer__button"
                  dangerouslySetInnerHTML={{
                    __html: messages.link.termsOfServices,
                  }}
                />
                <div className="login-form-container__footer__vertical-line" />
                <div
                  className="login-form-container__footer__button"
                  dangerouslySetInnerHTML={{
                    __html: messages.link.privacyPolicy,
                  }}
                />
              </div>
            </Page.Body>
          </Page.Section>
        </Page>
      ),
    [isLoading, login, messages, redirect, token]
  );

  return content;
};

Login.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: selectors.authentication.getIsLoading(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(Login);
