import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  LevelContent,
  Navbar,
  PageLoader,
  Topbar,
  Zoom,
} from 'src/components/common';

import LevelCard from './components/LevelCard';

import './index.scss';

const FlashCardsContainer = ({
  className,
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();

  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [level, setLevel] = useState(0);

  const [playAudio, isAudioPlaying] = useAudio();

  const handleNextLevel = useCallback(
    (isCorrect) => {
      if (level < items.length - 1) {
        setLevel((prevLevel) => prevLevel + 1);
      }

      handleLevelAnswer(items[level].id, isCorrect, currency?.id);
      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);
      setMotivationParrotVisibility(true);
    },
    [level, items, handleLevelAnswer, currency, setMotivationParrotVisibility]
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      if (isAudioPlaying) {
        return;
      }

      if (!displayModal) {
        if (items[level].audioUrl !== '') {
          playAudio(items[level].audioUrl, () => {
            setDisplayModal(true);
          });
        } else {
          setDisplayModal(true);
        }

        setMotivationParrotVisibility(false);

        return;
      }

      playAudio(feedback.COIN_COLLECT_SOUND_URL, () =>
        handleNextLevel(isCorrect)
      );
    },
    [
      displayModal,
      isAudioPlaying,
      items,
      level,
      handleNextLevel,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  useEffect(() => {
    if (collectedCoins >= items.length && items.length > 0 && !displayModal) {
      playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
    }
    // playAudio in deps causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedCoins, displayModal, handleCollectionEnd, items.length]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.flashCards}
        videoName={video.NAME.FLASH_CARDS}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className={cn('flash-cards-levels-container', className)}>
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(items[level].audioUrl, null, false)
            }
          >
            <LevelContent.Text label={items[level].key} size="small" />
            <LevelContent.Image
              alt={items[level].key}
              src={items[level].imageUrl}
            />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>

        <div className="flash-cards-levels-container__body">
          <Zoom mobileWidth={320} mobileHeight={320}>
            <LevelCard level={items[level]} onClick={handleAnswer} />
          </Zoom>
        </div>
      </Container>
    </PageLoader>
  );
};

FlashCardsContainer.propTypes = {
  className: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      imageUrl: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ),
  navigation: PropTypes.node,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

FlashCardsContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(FlashCardsContainer);
