import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import { actions, selectors } from 'src/store';
import { notifications, paths } from 'src/constants';
import { useIntlMessages, useQuery } from 'src/hooks';

import { Button, LogoTitle, Modal, Page } from 'src/components/common';

import { FormContainer } from './components';

import './index.scss';

const ResetPassword = ({ hasSubmitted, resetPassword, showNotification }) => {
  const history = useHistory();
  const messages = useIntlMessages();
  const query = useQuery();
  const token = query.get('token');

  useEffect(() => {
    if (!token) {
      history.push(paths.BASE);
    }
  }, [token, history]);

  const resetUserPassword = useCallback(
    (data) => {
      resetPassword({
        token,
        password: data.password,
        repeatPassword: data.repeatPassword,
      }).then(() => {
        history.push(paths.BASE);
        showNotification(
          messages.page.resetPassword.dialog.description,
          notifications.type.SUCCESS,
          notifications.duration.SHORT
        );
      });
    },
    [
      resetPassword,
      token,
      history,
      showNotification,
      messages.page.resetPassword.dialog.description,
    ]
  );

  const handleContinue = useCallback(() => history.push(paths.BASE), [history]);

  return (
    <>
      <Modal show={hasSubmitted}>
        <Modal.Title>{messages.page.resetPassword.dialog.title}</Modal.Title>
        <Modal.Description>
          {messages.page.resetPassword.dialog.description}
        </Modal.Description>
        <div className="container__buttons">
          <Button
            className="container__buttons"
            onClick={handleContinue}
            label={messages.button.forward}
          />
        </div>
      </Modal>
      <Page className="forgot-password-page" noNavigation>
        <Page.Section>
          {!isMobile && (
            <Page.Body noPadding color="transparent" collapse>
              <LogoTitle />
            </Page.Body>
          )}
          <Page.Body size="sm" color="light" align="center">
            <FormContainer resetUserPassword={resetUserPassword} />
          </Page.Body>
        </Page.Section>
      </Page>
    </>
  );
};

ResetPassword.propTypes = {
  hasSubmitted: PropTypes.bool.isRequired,
  resetPassword: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  hasSubmitted: selectors.authentication.getHasSubmitted(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
  showNotification: actions.notifications.showNotification,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ResetPassword);
