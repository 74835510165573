import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ContentContainer, Image } from 'src/components/common';

import './index.scss';

const LevelCard = ({
  className,
  index,
  level,
  onClick,
  selectedTerms,
  solvedTermKeys,
  type,
}) => {
  const isOpened = selectedTerms.some((term) => term.index === index);
  const isSolved = solvedTermKeys.some((key) => key === level.key);

  const levelCardClassNames = useMemo(
    () =>
      cn(
        'memory-level-card-container',
        `memory-level-card-container--${type}`,
        {
          [`${className}`]: !isOpened,
          [`memory-level-card-container--solved`]: isSolved,
        }
      ),
    [className, type, isOpened, isSolved]
  );

  const levelCardImageClassNames = useMemo(
    () =>
      cn(`memory-level-card-container__image`, {
        [`memory-level-card-container--opened__image`]: isOpened,
      }),
    [isOpened]
  );

  return (
    <ContentContainer
      className={levelCardClassNames}
      onClick={() => onClick(level, index)}
    >
      <Image
        className={levelCardImageClassNames}
        alt="memory-group-card"
        src={level.imageUrl}
      />
    </ContentContainer>
  );
};

LevelCard.propTypes = {
  className: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  level: PropTypes.shape({
    key: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
  }).isRequired,
  selectedTerms: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
    })
  ),
  solvedTermKeys: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
};

LevelCard.defaultProps = {
  selectedTerms: [],
  solvedTermKeys: [],
};

export default memo(LevelCard);
