import { createSelector } from 'reselect';

const getIsLoading = createSelector(
  (state) => state.subscriptionStatus.isLoading,
  (hasLoaded) => hasLoaded
);

const getHasLoaded = createSelector(
  (state) => state.subscriptionStatus.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getSubscriptionStatus = createSelector(
  (state) => state.subscriptionStatus.data,
  (subscriptionStatus = {}) => subscriptionStatus
);

const getShowTrialEndedModal = createSelector(
  (state) => state.subscriptionStatus.showTrialEndedModal,
  (showTrialEndedModal) => showTrialEndedModal
);

const getHasActiveSubscription = createSelector(
  (state) =>
    !!(
      state.subscriptionStatus.data.isSubscription ||
      state.subscriptionStatus.data.isTrial
    ),
  (isSubscription) => isSubscription
);

export default {
  getIsLoading,
  getHasLoaded,
  getSubscriptionStatus,
  getShowTrialEndedModal,
  getHasActiveSubscription,
};
