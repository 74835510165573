import CouponSettings from './CouponSettings';
import DailyMapSettings from './DailyMapSettings';
import License from './License';
import Payment from './Payment';
import AppSettings from './AppSettings';
import SpeechSoundSelection from './SpeechSoundSelection';
import StudentSettings from './StudentSettings';
import PaymentRouter from './PaymentRouter';
import SubscriptionStatus from './SubscriptionStatus';
import SubscriptionWizard from './SubscriptionWizard';
import SubscriptionPromo from './SubscriptionPromo';
import Subscriptions from './Subscriptions';
import UserSettings from './UserSettings';

export default {
  CouponSettings,
  DailyMapSettings,
  License,
  Payment,
  AppSettings,
  SpeechSoundSelection,
  StudentSettings,
  PaymentRouter,
  SubscriptionStatus,
  SubscriptionWizard,
  Subscriptions,
  UserSettings,
  SubscriptionPromo,
};
