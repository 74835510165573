import activities from './activities';
import activityGroups from './activityGroups';
import articulationGymnastics from './articulationGymnastics';
import authentication from './authentication';
import climbStepOne from './climbStepOne';
import climbStepOneExtra from './climbStepOneExtra';
import climbStepThree from './climbStepThree';
import climbStepThreeExtra from './climbStepThreeExtra';
import climbStepTwo from './climbStepTwo';
import climbStepTwoExtra from './climbStepTwoExtra';
import coupons from './coupons';
import dailyMap from './dailyMap';
import discoverStepOne from './discoverStepOne';
import discoverStepOneExtra from './discoverStepOneExtra';
import discoverStepThree from './discoverStepThree';
import discoverStepThreeExtra from './discoverStepThreeExtra';
import discoverStepTwo from './discoverStepTwo';
import discoverStepTwoExtra from './discoverStepTwoExtra';
import feedback from './feedback';
import findWordsStepOne from './findWordsStepOne';
import findWordsStepOneExtra from './findWordsStepOneExtra';
import findWordsStepThree from './findWordsStepThree';
import findWordsStepThreeExtra from './findWordsStepThreeExtra';
import findWordsStepTwo from './findWordsStepTwo';
import findWordsStepTwoExtra from './findWordsStepTwoExtra';
import flashCardsStepOne from './flashCardsStepOne';
import flashCardsStepOneExtra from './flashCardsStepOneExtra';
import flashCardsStepThree from './flashCardsStepThree';
import flashCardsStepThreeExtra from './flashCardsStepThreeExtra';
import flashCardsStepTwo from './flashCardsStepTwo';
import flashCardsStepTwoExtra from './flashCardsStepTwoExtra';
import help from './help';
import licensing from './licensing';
import magicLink from './magicLink';
import memoryStepOne from './memoryStepOne';
import memoryStepOneExtra from './memoryStepOneExtra';
import memoryStepThree from './memoryStepThree';
import memoryStepThreeExtra from './memoryStepThreeExtra';
import memoryStepTwo from './memoryStepTwo';
import memoryStepTwoExtra from './memoryStepTwoExtra';
import notifications from './notifications';
import painting from './painting';
import parrot from './parrot';
import payment from './payment';
import promoCodes from './promoCodes';
import readAndThink from './readAndThink';
import readTogether from './readTogether';
import replenishment from './replenishment';
import speechSounds from './speechSounds';
import students from './students';
import subactivities from './subactivities';
import subscriptions from './subscriptions';
import tooltips from './tooltips';
import treasureHunt from './treasureHunt';
import userMeta from './userMeta';
import users from './users';
import wallet from './wallet';
import wheelOfFortune from './wheelOfFortune';

export default {
  activities,
  activityGroups,
  articulationGymnastics,
  authentication,
  climbStepOne,
  climbStepOneExtra,
  climbStepThree,
  climbStepThreeExtra,
  climbStepTwo,
  climbStepTwoExtra,
  coupons,
  dailyMap,
  discoverStepOne,
  discoverStepOneExtra,
  discoverStepThree,
  discoverStepThreeExtra,
  discoverStepTwo,
  discoverStepTwoExtra,
  feedback,
  findWordsStepOne,
  findWordsStepOneExtra,
  findWordsStepThree,
  findWordsStepThreeExtra,
  findWordsStepTwo,
  findWordsStepTwoExtra,
  flashCardsStepOne,
  flashCardsStepOneExtra,
  flashCardsStepThree,
  flashCardsStepThreeExtra,
  flashCardsStepTwo,
  flashCardsStepTwoExtra,
  help,
  licensing,
  magicLink,
  memoryStepOne,
  memoryStepOneExtra,
  memoryStepThree,
  memoryStepThreeExtra,
  memoryStepTwo,
  memoryStepTwoExtra,
  notifications,
  painting,
  parrot,
  payment,
  promoCodes,
  readAndThink,
  readTogether,
  replenishment,
  speechSounds,
  students,
  subactivities,
  subscriptions,
  tooltips,
  treasureHunt,
  userMeta,
  users,
  wallet,
  wheelOfFortune,
};
