import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { common } from 'src/utils';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  Card,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  PageLoader,
  Navbar,
  Zoom,
  Topbar,
} from 'src/components/common';

import './index.scss';
import AnswerCard from './components/AnswerCard';

const SHAKE_DELAY = 500;

const ReadAndThinkBonusLevelsContainer = ({
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  student,
}) => {
  const messages = useIntlMessages();

  const [answers, setAnswers] = useState([]);
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [level, setLevel] = useState(0);
  const [shakeAnswerImageUrl, setShakeAnswerImageUrl] = useState('');
  const [showAnswer, setShowAnswer] = useState(false);
  const [playAudio, isAudioPlaying] = useAudio();

  const pronounceQuestion = useCallback(() => {
    if (isAudioPlaying) {
      return;
    }

    playAudio(items[level].questionAudioUrl);
  }, [isAudioPlaying, items, level, playAudio]);

  const handleAnswer = useCallback(
    (isCorrect) => {
      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (collectedCoins + 1 >= items.length && items.length > 0) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      if (level < items.length - 1) {
        setLevel((prevLevel) => prevLevel + 1);
      }

      handleLevelAnswer(items[level].id, isCorrect, currency.id);

      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);
      setShowAnswer(false);
    },
    [
      collectedCoins,
      currency.id,
      handleCollectionEnd,
      handleLevelAnswer,
      items,
      level,
      playAudio,
    ]
  );

  const checkAnswer = useCallback(
    (answer) => {
      if (isAudioPlaying) {
        return;
      }

      if (answer === items[level].correctImageUrl) {
        playAudio(feedback.HAPPY_SOUND_URL, () => {
          setShowAnswer(true);
          if (items[level].answerAudioUrl !== '') {
            playAudio(items[level].answerAudioUrl, () => {
              setDisplayModal(true);
            });
          }
        });
      } else {
        if (student.hasEnabledErrorSound) {
          playAudio(feedback.BAD_SOUND_URL);
        }

        handleLevelAnswer(items[level].id, false, currency?.id);

        setShakeAnswerImageUrl(answer);

        setTimeout(() => {
          setShakeAnswerImageUrl('');
        }, SHAKE_DELAY);
      }
    },
    [
      currency,
      handleLevelAnswer,
      isAudioPlaying,
      items,
      level,
      playAudio,
      student.hasEnabledErrorSound,
    ]
  );

  useEffect(() => {
    if (hasLoaded) {
      let allAnswers = [
        items[level].firstDistractorImageUrl,
        items[level].secondDistractorImageUrl,
        items[level].correctImageUrl,
      ];

      allAnswers = common.shuffle(allAnswers);

      setAnswers(allAnswers);
    }
  }, [hasLoaded, level, items]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.readAndThink}
        videoName={video.NAME.READ_AND_THINK}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className="read-and-think-bonus-levels-container">
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(items[level].answerAudioUrl, null, false)
            }
          >
            <p className="read-and-think-bonus-levels-container__feedback-sentence">
              {items[level].answer}
            </p>
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>

        <Zoom
          mobileWidth={320}
          mobileHeight={320}
          align="center"
          mobileRatio="calc(100% - 140px)"
        >
          <div className="read-and-think-bonus-levels-container__body">
            <Card
              className="read-and-think-bonus-levels-container__body__question-container"
              onClick={() => pronounceQuestion()}
            >
              <p>{items[level].question}</p>
            </Card>
            <div className="read-and-think-bonus-levels-container__body__answers-container">
              {answers.map((answer, index) => (
                <AnswerCard
                  key={index}
                  answer={answer}
                  shakeAnswerImageUrl={shakeAnswerImageUrl}
                  onClick={checkAnswer}
                />
              ))}
            </div>
            <Card
              className="read-and-think-bonus-levels-container__body__answer-container"
              ghost={!showAnswer}
            >
              <p>{items[level].answer}</p>
            </Card>
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

ReadAndThinkBonusLevelsContainer.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      answerAudioUrl: PropTypes.string,
      correctAudioUrl: PropTypes.string,
      correctImageUrl: PropTypes.string,
      firstDistractorImageUrl: PropTypes.string,
      id: PropTypes.number,
      question: PropTypes.string,
      questionAudioUrl: PropTypes.string,
      secondDistractorImageUrl: PropTypes.string,
    })
  ),
  navigation: PropTypes.node,
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
};

ReadAndThinkBonusLevelsContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

export default memo(ReadAndThinkBonusLevelsContainer);
