import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions, selectors } from 'src/store';

import { OverlayLoader, Page } from 'src/components/common';

import { PasswordSetup, PersonalData } from './components';

import './index.scss';

const UserSettings = ({ isLoading, user, updateAuthUser }) => {
  const handleSubmit = useCallback((data) => updateAuthUser(data), [
    updateAuthUser,
  ]);

  const content = (
    <Page className="user-settings-container">
      <Page.Section>
        {isLoading && <OverlayLoader />}
        <Page.Body color="light">
          <PersonalData
            defaultValues={{
              childName: user.childName,
              fullName: user.fullName,
              email: user.email,
              phoneNumber: user.phoneNumber,
            }}
            onSubmit={handleSubmit}
            isSpeechTherapist={!!user.SpeechTherapistId}
          />
        </Page.Body>
        <Page.Body>
          <PasswordSetup onSubmit={handleSubmit} />
        </Page.Body>
      </Page.Section>
    </Page>
  );

  return content;
};

UserSettings.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  updateAuthUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    childName: PropTypes.string,
    email: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    phoneNumber: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: selectors.authentication.getUser(state),
  isLoading: selectors.authentication.getIsLoading(state),
});

const mapDispatchToProps = {
  ...actions.authentication,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(UserSettings);
