import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';

import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  LevelContent,
  Navbar,
  PageLoader,
  Topbar,
} from 'src/components/common';

import LevelScene from './components/LevelScene';

import './index.scss';

const getFinalClimberPositionImageUrl = (url) => {
  const imageNumber = parseInt(url[url.length - 5], 10) + 1;
  return (
    url.substr(0, url.length - 5) + imageNumber + url.substr(url.length - 4)
  );
};

const getFinalLevel = (items) => {
  const { awardImageUrl, climberPositionImageUrl } = items[items.length - 1];
  const finalClimberPositionImageUrl = getFinalClimberPositionImageUrl(
    climberPositionImageUrl
  );

  return {
    key: 'final',
    awardImageUrl,
    climberPositionImageUrl: finalClimberPositionImageUrl,
  };
};

const ClimbContainer = ({
  awardClassName,
  className,
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  subactivityName,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();

  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [level, setLevel] = useState(0);
  const [showReward, setShowReward] = useState(false);
  const [playAudio, isAudioPlaying, setIsAudioPlaying] = useAudio();

  const handleNextLevel = useCallback(
    (isCorrect) => {
      handleLevelAnswer(items[level].id, isCorrect, currency?.id);

      setDisplayModal(false);
      setIsAudioPlaying(false);
      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);

      if (level + 1 >= items.length) {
        // @TODO: Add animation dance after reward is collected
        setShowReward(true);

        playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
      } else {
        setLevel((prevLevel) => prevLevel + 1);
        setMotivationParrotVisibility(true);
      }
    },
    [
      currency,
      handleCollectionEnd,
      handleLevelAnswer,
      items,
      level,
      playAudio,
      setIsAudioPlaying,
      setMotivationParrotVisibility,
    ]
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      if (isAudioPlaying) {
        return;
      }

      if (!displayModal) {
        if (items[level].audioUrl !== '') {
          playAudio(items[level].audioUrl, () => {
            setDisplayModal(true);
          });
        } else {
          setDisplayModal(true);
        }

        setMotivationParrotVisibility(false);
        return;
      }

      playAudio(feedback.COIN_COLLECT_SOUND_URL, () =>
        handleNextLevel(isCorrect)
      );
    },
    [
      displayModal,
      items,
      level,
      handleNextLevel,
      isAudioPlaying,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.subactivity[subactivityName]}
        videoName={video.NAME.CLIMB}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className={cn('climb-levels-container', className)}>
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(items[level].audioUrl, null, false)
            }
          >
            <LevelContent.Text label={items[level].label} />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>

        <LevelScene
          awardClassName={awardClassName}
          level={showReward ? getFinalLevel(items) : items[level]}
          showReward={showReward}
          onClick={handleAnswer}
        />
      </Container>
    </PageLoader>
  );
};

ClimbContainer.propTypes = {
  awardClassName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  navigation: PropTypes.node,
  subactivityName: PropTypes.string.isRequired,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

ClimbContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(connect(null, mapDispatchToProps), memo)(ClimbContainer);
