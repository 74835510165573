const getActivityGroupId = (url) => {
  const activitySubstring = url.substring(
    url.indexOf('activity') + 'activity'.length + 1
  );

  const indexOfSlash = activitySubstring.indexOf('/');

  const groupKeySubstring = activitySubstring.substring(
    0,
    indexOfSlash !== -1 ? indexOfSlash : activitySubstring.length
  );

  return groupKeySubstring.substring(groupKeySubstring.lastIndexOf('-') + 1);
};

export default {
  getActivityGroupId,
};
