import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _last from 'lodash/last';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  GameCoins,
  PageLoader,
  SentenceCreator,
  FeedbackContainer,
  FeedbackPreview,
  FeedbackPanel,
  Navbar,
  Zoom,
  Card,
  LevelContent,
  ReadGames,
  Topbar,
} from 'src/components/common';

import './index.scss';

const ReadAndThinkLevelsContainer = ({
  collection,
  currency,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  studentSpeechSound,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();

  const [awardedSentenceIds, setAwardedSentenceIds] = useState([]);
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [level, setLevel] = useState(0);
  const [sentences, setSentences] = useState([]);
  const [playAudio, isAudioPlaying] = useAudio();

  const containerClassNames = useMemo(
    () =>
      cn(
        'read-and-think-levels-container',
        `read-and-think-levels-container--background-${studentSpeechSound.label}`
      ),
    [studentSpeechSound.label]
  );

  const handleAnswer = useCallback(
    (isCorrect) => {
      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (collectedCoins + 1 >= items.length && items.length > 0) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      if (level < items.length - 1) {
        if ((level + 1) % 3 === 0) {
          setSentences([]);
        }

        setAwardedSentenceIds([...awardedSentenceIds, items[level].id]);

        setLevel((prevLevel) => prevLevel + 1);
      }

      handleLevelAnswer(items[level].id, isCorrect, currency?.id);

      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);
      setMotivationParrotVisibility(true);
    },
    [
      awardedSentenceIds,
      collectedCoins,
      currency,
      handleCollectionEnd,
      handleLevelAnswer,
      items,
      level,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  const handleSentenceSelect = useCallback(
    (selectedSentenceId) => {
      if (isAudioPlaying) {
        return;
      }

      setMotivationParrotVisibility(false);

      if (awardedSentenceIds.includes(selectedSentenceId)) {
        const selectedSentence = items.find(
          (item) => item.id === selectedSentenceId
        );

        if (selectedSentence.sentenceAudioUrl !== '') {
          playAudio(selectedSentence.sentenceAudioUrl);
        }

        return;
      }

      if (items[level].sentenceAudioUrl !== '') {
        setDisplayModal(false);
        playAudio(items[level].sentenceAudioUrl, () => {
          setDisplayModal(true);
        });
      } else {
        setDisplayModal(true);
      }
    },
    [
      awardedSentenceIds,
      setDisplayModal,
      isAudioPlaying,
      items,
      level,
      playAudio,
      setMotivationParrotVisibility,
    ]
  );

  const playTitleAudio = useCallback(
    (titleAudioUrl) => {
      if (isAudioPlaying) {
        return;
      }

      setMotivationParrotVisibility(false);

      if (titleAudioUrl !== '') {
        playAudio(titleAudioUrl);
      }
    },
    [isAudioPlaying, playAudio, setMotivationParrotVisibility]
  );

  useEffect(() => {
    if (!hasLoaded) {
      setSentences([]);
    }

    if (hasLoaded) {
      setMotivationParrotVisibility(true);

      const sentenceImages = items[level].sentenceImages.map(
        (sentenceImage) => ({
          imageUrl: sentenceImage.imageUrl,
          key: sentenceImage.key,
        })
      );

      const createdSentence = (
        <SentenceCreator
          imageClassNames="read-and-think-sentence-image"
          initialSentence={items[level].sentence}
          sentenceImages={sentenceImages}
        />
      );

      setSentences((sentence) => [
        ...sentence,
        { id: items[level].id, createdSentence },
      ]);
    }
  }, [hasLoaded, level, items, setMotivationParrotVisibility]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.readAndThink}
        videoName={video.NAME.READ_AND_THINK}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className={containerClassNames}>
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(items[level].sentenceAudioUrl, null, false)
            }
          >
            <LevelContent.Sentence>
              {!!sentences.length && _last(sentences).createdSentence}
            </LevelContent.Sentence>
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>
        <Zoom
          mobileWidth={320}
          mobileHeight={405}
          mobileRatio="calc(100% - 140px)"
          align="center"
        >
          <div className="read-and-think-levels-container__body">
            <Card
              className="read-and-think-levels-container__title"
              onClick={() => playTitleAudio(collection.titleAudioUrl)}
            >
              {collection.title}
            </Card>
            <ReadGames.Sentences
              sentencesCount={items.length}
              screenSentences={sentences}
              onClick={handleSentenceSelect}
              level={level}
            />
          </div>
        </Zoom>
      </Container>
    </PageLoader>
  );
};

ReadAndThinkLevelsContainer.propTypes = {
  collection: PropTypes.shape({
    title: PropTypes.string,
    titleAudioUrl: PropTypes.string,
  }),
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sentence: PropTypes.string.isRequired,
      sentenceAudioUrl: PropTypes.string.isRequired,
      sentenceImages: PropTypes.arrayOf(
        PropTypes.shape({
          imageUrl: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  navigation: PropTypes.node,
  studentSpeechSound: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

ReadAndThinkLevelsContainer.defaultProps = {
  navigation: null,
  currency: null,
  collection: null,
  items: [],
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(ReadAndThinkLevelsContainer);
