import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

const InfoField = ({
  className,
  labelClassName,
  valueClassName,
  label,
  value,
  info,
}) => {
  const containerClassNames = cn('info-field', className);
  const labelClassNames = cn('info-field__label', labelClassName);
  const valueClassNames = cn('info-field__value', valueClassName);
  const infoClassNames = cn('info-field__info', valueClassName);

  return (
    <div className={containerClassNames}>
      <div className={labelClassNames}>{label}</div>
      {value && <div className={valueClassNames}>{value}</div>}
      <div className={infoClassNames}>{info}</div>
    </div>
  );
};

InfoField.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.node,
  info: PropTypes.node,
};

InfoField.defaultProps = {
  className: '',
  label: undefined,
  labelClassName: '',
  value: undefined,
  info: undefined,
  valueClassName: '',
};

export default InfoField;
