import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { paths } from 'src/constants';

const hideButtonStyleId = 'hide-button-style';
const hideButtonStyle = '#CookiebotWidget { display: none; }';

const Cookiebot = () => {
  const location = useLocation();

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.id = hideButtonStyleId;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    const styleElement = document.getElementById(hideButtonStyleId);

    if (location.pathname === paths.SETTINGS_APP) {
      styleElement.innerHTML = '';
    } else {
      styleElement.innerHTML = hideButtonStyle;
    }
  }, [location]);

  return null;
};

export default Cookiebot;
