import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { actions } from 'src/store';
import common from 'src/utils/common';
import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  LevelContent,
  PageLoader,
  Navbar,
  Topbar,
} from 'src/components/common';

import LevelScene from './components/LevelScene';

import './index.scss';

const AWARD_COVER_POSITIONS = [1, 3, 4, 5];
const SHAKE_DURATION = 500;
const TERM_COLORS = ['green', 'red', 'orange', 'purple', 'blue'];

const DiscoverContainer = ({
  className,
  currency,
  groupKey,
  handleCollectionEnd,
  handleLevelAnswer,
  hasLoaded,
  items,
  navigation,
  student,
  setMotivationParrotVisibility,
}) => {
  const messages = useIntlMessages();

  const [cleanedTerms, setCleanedTerms] = useState([]);
  const [collectedCoins, setCollectedCoins] = useState(0);
  const [coverPositions, setCoverPositions] = useState([]);
  const [discoveredPositions, setDiscoveredPosition] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [level, setLevel] = useState(0);
  const [canPaintPairs, setCanPaintPairs] = useState(false);
  const [sceneTerms, setSceneTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState({});
  const [shakeTermId, setShakeTermId] = useState(null);

  const [playAudio, isAudioPlaying] = useAudio();

  const handleAnswer = useCallback(
    (isCorrect) => {
      playAudio(feedback.COIN_COLLECT_SOUND_URL, () => {
        if (collectedCoins + 1 >= items.length && items.length > 0) {
          playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
        }
      });

      handleLevelAnswer(selectedTerm.id, isCorrect, currency?.id);
      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);

      setCleanedTerms([...cleanedTerms, selectedTerm.id]);

      setDiscoveredPosition([...discoveredPositions, coverPositions[level]]);
      setLevel((prevLevel) => prevLevel + 1);
      setCanPaintPairs(false);
      setMotivationParrotVisibility(true);
    },
    [
      cleanedTerms,
      collectedCoins,
      coverPositions,
      currency,
      discoveredPositions,
      handleCollectionEnd,
      handleLevelAnswer,
      items.length,
      level,
      playAudio,
      selectedTerm.id,
      setMotivationParrotVisibility,
    ]
  );

  const selectTerm = useCallback(
    (term) => {
      if (isAudioPlaying) {
        return;
      }
      setMotivationParrotVisibility(false);
      setSelectedTerm(term);
    },
    [isAudioPlaying, setMotivationParrotVisibility]
  );

  const cleanTerm = useCallback(
    (term) => {
      if (cleanedTerms.includes(term.id) || isAudioPlaying) {
        return;
      }

      if (selectedTerm.id === term.id) {
        setCanPaintPairs(true);

        playAudio(feedback.HAPPY_SOUND_URL, () => {
          if (!displayModal) {
            if (term.audioUrl !== '') {
              playAudio(term.audioUrl, () => {
                setDisplayModal(true);
              });
            } else {
              setDisplayModal(true);
            }
            setMotivationParrotVisibility(false);
          }
        });
      } else {
        if (student.hasEnabledErrorSound) {
          playAudio(feedback.BAD_SOUND_URL);
        }

        setShakeTermId(term.id);

        setTimeout(() => {
          setShakeTermId(null);
        }, SHAKE_DURATION);
      }
    },
    [
      cleanedTerms,
      displayModal,
      isAudioPlaying,
      playAudio,
      selectedTerm.id,
      student.hasEnabledErrorSound,
      setMotivationParrotVisibility,
    ]
  );

  useEffect(() => {
    const coverPositionsArray = common.shuffle(AWARD_COVER_POSITIONS);
    const termColors = common.shuffle(TERM_COLORS);

    const terms = items.map((item, index) => ({
      ...item,
      color: termColors[index],
      pairColor: termColors[index],
    }));

    const termsCopy = common.shuffle(terms);

    const allTerms = [];
    terms.forEach((item, index) => {
      allTerms.push(item);
      allTerms.push(termsCopy[index]);
    });

    setCoverPositions(coverPositionsArray.concat(2));
    setSceneTerms(allTerms);
  }, [items]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.game.discover}
        videoName={video.NAME.DISCOVER}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className={cn('discover-levels-container', className)}>
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Sound
            handleListenAgain={() =>
              playAudio(selectedTerm.audioUrl, null, false)
            }
          >
            <LevelContent.Text
              label={`${selectedTerm.label}-${selectedTerm.pairLabel}`}
            />
          </FeedbackPreview.Sound>
          <FeedbackPanel.Grades onClick={handleAnswer} />
        </FeedbackContainer>

        {sceneTerms.length > 0 && (
          <LevelScene
            canPaintPairs={canPaintPairs}
            cleanTerm={cleanTerm}
            cleanedTerms={cleanedTerms}
            discoveredPositions={discoveredPositions}
            sceneTerms={sceneTerms}
            selectTerm={selectTerm}
            selectedTermId={selectedTerm.id}
            shakeTermId={shakeTermId}
            groupKey={groupKey}
          />
        )}
      </Container>
    </PageLoader>
  );
};

DiscoverContainer.propTypes = {
  className: PropTypes.string.isRequired,
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  groupKey: PropTypes.string.isRequired,
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      audioUrl: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  navigation: PropTypes.node,
  student: PropTypes.shape({
    hasEnabledErrorSound: PropTypes.bool.isRequired,
  }).isRequired,
  setMotivationParrotVisibility: PropTypes.func.isRequired,
};

DiscoverContainer.defaultProps = {
  navigation: null,
  currency: null,
  items: [],
};

const mapDispatchToProps = {
  ...actions.feedback,
};

export default compose(
  connect(null, mapDispatchToProps),
  memo
)(DiscoverContainer);
