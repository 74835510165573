import React from 'react';
import PropTypes from 'prop-types';

import { subscriptions as subscriptionsConstants } from 'src/constants';
import { subscriptions as subscriptionsUtils } from 'src/utils';

import { Localize, RadioButton } from 'src/components/common';

import './index.scss';

const DurationRadioButton = ({
  duration,
  radioButtonProps,
  selectedValue,
  subscription,
}) => {
  const getPriceForDuration = (durationKey, subscriptionPrice) => {
    const durationInMonths =
      subscriptionsConstants.DURATION_VALUES[durationKey];

    return durationInMonths * subscriptionPrice;
  };

  const price = subscriptionsUtils.roundNumber(
    getPriceForDuration(duration.key, subscription.price)
  );
  const priceWithDiscount = subscriptionsUtils.getDiscountedPriceForDuration(
    duration.key,
    subscription.price
  );

  return (
    <RadioButton
      checked={selectedValue === duration.key}
      value={duration.key}
      {...radioButtonProps}
      className="duration-radio-button"
    >
      <div className="duration-radio-button__label-container">
        {duration.value}
        <div className="duration-radio-button__label-container__prices">
          {price !== priceWithDiscount && (
            <div className="duration-radio-button__label-container__prices__price">
              <div className="duration-radio-button__label-container__prices__price__amount">
                {price !== '0.00' && (
                  <Localize.Currency
                    id="meta.alternateCurrency"
                    value={price}
                    locale="de"
                  />
                )}
              </div>
            </div>
          )}
          <div className="duration-radio-button__label-container__prices__price">
            <div className="duration-radio-button__label-container__prices__price__amount--highlighted">
              {priceWithDiscount !== '0.00' && (
                <Localize.Currency
                  id="meta.alternateCurrency"
                  value={priceWithDiscount}
                  locale="de"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </RadioButton>
  );
};

DurationRadioButton.propTypes = {
  duration: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  radioButtonProps: PropTypes.shape({}).isRequired,
  subscription: PropTypes.shape({
    price: PropTypes.number,
    alternatePrice: PropTypes.number,
  }).isRequired,
  selectedValue: PropTypes.string,
};

DurationRadioButton.defaultProps = {
  selectedValue: undefined,
};

export default DurationRadioButton;
