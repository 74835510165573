import activities from './activities';
import activityGroups from './activityGroups';
import articulationGymnasticsCollections from './articulationGymnasticsCollections';
import articulationGymnasticsLevels from './articulationGymnasticsLevels';
import authentication from './authentication';
import climbStepOneCollections from './climbStepOneCollections';
import climbStepOneExtraCollections from './climbStepOneExtraCollections';
import climbStepOneExtraLevels from './climbStepOneExtraLevels';
import climbStepOneLevels from './climbStepOneLevels';
import climbStepThreeCollections from './climbStepThreeCollections';
import climbStepThreeExtraCollections from './climbStepThreeExtraCollections';
import climbStepThreeExtraLevels from './climbStepThreeExtraLevels';
import climbStepThreeLevels from './climbStepThreeLevels';
import climbStepTwoCollections from './climbStepTwoCollections';
import climbStepTwoExtraCollections from './climbStepTwoExtraCollections';
import climbStepTwoExtraLevels from './climbStepTwoExtraLevels';
import climbStepTwoLevels from './climbStepTwoLevels';
import coupons from './coupons';
import dailyMap from './dailyMap';
import discoverStepOneCollections from './discoverStepOneCollections';
import discoverStepOneExtraCollections from './discoverStepOneExtraCollections';
import discoverStepOneExtraLevels from './discoverStepOneExtraLevels';
import discoverStepOneLevels from './discoverStepOneLevels';
import discoverStepThreeCollections from './discoverStepThreeCollections';
import discoverStepThreeExtraCollections from './discoverStepThreeExtraCollections';
import discoverStepThreeExtraLevels from './discoverStepThreeExtraLevels';
import discoverStepThreeLevels from './discoverStepThreeLevels';
import discoverStepTwoCollections from './discoverStepTwoCollections';
import discoverStepTwoExtraCollections from './discoverStepTwoExtraCollections';
import discoverStepTwoExtraLevels from './discoverStepTwoExtraLevels';
import discoverStepTwoLevels from './discoverStepTwoLevels';
import feedback from './feedback';
import findWordsStepOneCollections from './findWordsStepOneCollections';
import findWordsStepOneExtraCollections from './findWordsStepOneExtraCollections';
import findWordsStepOneExtraLevels from './findWordsStepOneExtraLevels';
import findWordsStepOneLevels from './findWordsStepOneLevels';
import findWordsStepThreeCollections from './findWordsStepThreeCollections';
import findWordsStepThreeExtraCollections from './findWordsStepThreeExtraCollections';
import findWordsStepThreeExtraLevels from './findWordsStepThreeExtraLevels';
import findWordsStepThreeLevels from './findWordsStepThreeLevels';
import findWordsStepTwoCollections from './findWordsStepTwoCollections';
import findWordsStepTwoExtraCollections from './findWordsStepTwoExtraCollections';
import findWordsStepTwoExtraLevels from './findWordsStepTwoExtraLevels';
import findWordsStepTwoLevels from './findWordsStepTwoLevels';
import flashCardsStepOneCollections from './flashCardsStepOneCollections';
import flashCardsStepOneExtraCollections from './flashCardsStepOneExtraCollections';
import flashCardsStepOneExtraLevels from './flashCardsStepOneExtraLevels';
import flashCardsStepOneLevels from './flashCardsStepOneLevels';
import flashCardsStepThreeCollections from './flashCardsStepThreeCollections';
import flashCardsStepThreeExtraCollections from './flashCardsStepThreeExtraCollections';
import flashCardsStepThreeExtraLevels from './flashCardsStepThreeExtraLevels';
import flashCardsStepThreeLevels from './flashCardsStepThreeLevels';
import flashCardsStepTwoCollections from './flashCardsStepTwoCollections';
import flashCardsStepTwoExtraCollections from './flashCardsStepTwoExtraCollections';
import flashCardsStepTwoExtraLevels from './flashCardsStepTwoExtraLevels';
import flashCardsStepTwoLevels from './flashCardsStepTwoLevels';
import help from './help';
import licensing from './licensing';
import memoryStepOneCollections from './memoryStepOneCollections';
import memoryStepOneExtraCollections from './memoryStepOneExtraCollections';
import memoryStepOneExtraGroups from './memoryStepOneExtraGroups';
import memoryStepOneExtraLevels from './memoryStepOneExtraLevels';
import memoryStepOneGroups from './memoryStepOneGroups';
import memoryStepOneLevels from './memoryStepOneLevels';
import memoryStepThreeCollections from './memoryStepThreeCollections';
import memoryStepThreeExtraCollections from './memoryStepThreeExtraCollections';
import memoryStepThreeExtraGroups from './memoryStepThreeExtraGroups';
import memoryStepThreeExtraLevels from './memoryStepThreeExtraLevels';
import memoryStepThreeGroups from './memoryStepThreeGroups';
import memoryStepThreeLevels from './memoryStepThreeLevels';
import memoryStepTwoCollections from './memoryStepTwoCollections';
import memoryStepTwoExtraCollections from './memoryStepTwoExtraCollections';
import memoryStepTwoExtraGroups from './memoryStepTwoExtraGroups';
import memoryStepTwoExtraLevels from './memoryStepTwoExtraLevels';
import memoryStepTwoGroups from './memoryStepTwoGroups';
import memoryStepTwoLevels from './memoryStepTwoLevels';
import notes from './notes';
import notifications from './notifications';
import paintingCollections from './paintingCollections';
import paintingGroups from './paintingGroups';
import paintingLevels from './paintingLevels';
import parrot from './parrot';
import payment from './payment';
import promoCodes from './promoCodes';
import readAndThinkBonusLevels from './readAndThinkBonusLevels';
import readAndThinkCollections from './readAndThinkCollections';
import readAndThinkLevels from './readAndThinkLevels';
import readTogetherBonusLevels from './readTogetherBonusLevels';
import readTogetherCollections from './readTogetherCollections';
import readTogetherLevels from './readTogetherLevels';
import replenishmentCollections from './replenishmentCollections';
import replenishmentLevels from './replenishmentLevels';
import routing from './routing';
import speechSounds from './speechSounds';
import student from './student';
import students from './students';
import subactivities from './subactivities';
import subscriptionStatus from './subscriptionStatus';
import subscriptions from './subscriptions';
import tooltips from './tooltips';
import treasureHuntCollections from './treasureHuntCollections';
import treasureHuntGroups from './treasureHuntGroups';
import treasureHuntLevels from './treasureHuntLevels';
import user from './user';
import wallet from './wallet';
import wheelOfFortuneCollections from './wheelOfFortuneCollections';
import wheelOfFortuneLevels from './wheelOfFortuneLevels';

export default {
  activities,
  activityGroups,
  articulationGymnasticsCollections,
  articulationGymnasticsLevels,
  authentication,
  climbStepOneCollections,
  climbStepOneExtraCollections,
  climbStepOneExtraLevels,
  climbStepOneLevels,
  climbStepThreeCollections,
  climbStepThreeExtraCollections,
  climbStepThreeExtraLevels,
  climbStepThreeLevels,
  climbStepTwoCollections,
  climbStepTwoExtraCollections,
  climbStepTwoExtraLevels,
  climbStepTwoLevels,
  coupons,
  dailyMap,
  discoverStepOneCollections,
  discoverStepOneExtraCollections,
  discoverStepOneExtraLevels,
  discoverStepOneLevels,
  discoverStepThreeCollections,
  discoverStepThreeExtraCollections,
  discoverStepThreeExtraLevels,
  discoverStepThreeLevels,
  discoverStepTwoCollections,
  discoverStepTwoExtraCollections,
  discoverStepTwoExtraLevels,
  discoverStepTwoLevels,
  feedback,
  findWordsStepOneCollections,
  findWordsStepOneExtraCollections,
  findWordsStepOneExtraLevels,
  findWordsStepOneLevels,
  findWordsStepThreeCollections,
  findWordsStepThreeExtraCollections,
  findWordsStepThreeExtraLevels,
  findWordsStepThreeLevels,
  findWordsStepTwoCollections,
  findWordsStepTwoExtraCollections,
  findWordsStepTwoExtraLevels,
  findWordsStepTwoLevels,
  flashCardsStepOneCollections,
  flashCardsStepOneExtraCollections,
  flashCardsStepOneExtraLevels,
  flashCardsStepOneLevels,
  flashCardsStepThreeCollections,
  flashCardsStepThreeExtraCollections,
  flashCardsStepThreeExtraLevels,
  flashCardsStepThreeLevels,
  flashCardsStepTwoCollections,
  flashCardsStepTwoExtraCollections,
  flashCardsStepTwoExtraLevels,
  flashCardsStepTwoLevels,
  help,
  licensing,
  memoryStepOneCollections,
  memoryStepOneExtraCollections,
  memoryStepOneExtraGroups,
  memoryStepOneExtraLevels,
  memoryStepOneGroups,
  memoryStepOneLevels,
  memoryStepThreeCollections,
  memoryStepThreeExtraCollections,
  memoryStepThreeExtraGroups,
  memoryStepThreeExtraLevels,
  memoryStepThreeGroups,
  memoryStepThreeLevels,
  memoryStepTwoCollections,
  memoryStepTwoExtraCollections,
  memoryStepTwoExtraGroups,
  memoryStepTwoExtraLevels,
  memoryStepTwoGroups,
  memoryStepTwoLevels,
  notes,
  notifications,
  paintingCollections,
  paintingGroups,
  paintingLevels,
  parrot,
  payment,
  promoCodes,
  readAndThinkBonusLevels,
  readAndThinkCollections,
  readAndThinkLevels,
  readTogetherBonusLevels,
  readTogetherCollections,
  readTogetherLevels,
  replenishmentCollections,
  replenishmentLevels,
  routing,
  speechSounds,
  student,
  students,
  subactivities,
  subscriptionStatus,
  subscriptions,
  tooltips,
  treasureHuntCollections,
  treasureHuntGroups,
  treasureHuntLevels,
  user,
  wallet,
  wheelOfFortuneCollections,
  wheelOfFortuneLevels,
};
