import { actions, paths } from 'src/constants';

export default {
  getPromoCodeDiscount: (code) => ({
    [actions.API_CALL]: {
      types: [
        actions.PROMO_CODE_GET_REQUEST,
        actions.PROMO_CODE_GET_SUCCESS,
        actions.PROMO_CODE_GET_FAILURE,
      ],
      promise: (client) => client.get(paths.build(paths.api.PROMO_CODE, code)),
    },
  }),

  clearDiscount: () => ({
    type: actions.PROMO_CODE_CLEAR,
  }),
};
