import { createSelector } from 'reselect';

const getIsSubmitting = createSelector(
  (state) => state.feedback.isSubmitting,
  (isSubmitting) => isSubmitting
);

const getHasLoaded = createSelector(
  (state) => state.feedback.hasLoaded,
  (hasLoaded) => hasLoaded
);

const getFeedbackData = createSelector(
  (state) => state.feedback.data,
  (data = {}) => data
);

const getMotivationalAudioUrl = createSelector(
  (state) => state.feedback.motivationalAudioUrl,
  (motivationalAudioUrl) => motivationalAudioUrl
);

const getParrotVisibility = createSelector(
  (state) => state.feedback.isParrotVisible,
  (isParrotVisible) => isParrotVisible
);

export default {
  getFeedbackData,
  getHasLoaded,
  getIsSubmitting,
  getMotivationalAudioUrl,
  getParrotVisibility,
};
