import cn from 'classnames';
import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { actions, selectors } from 'src/store';

import Parrot from '../Parrot';
import Zoom from '../Zoom';

import './index.scss';

const AUDIO_PLAY_DELAY = 1500;
const OVERLAY_HIDE_DELAY = 750;

const ParrotMotivationOverlay = ({
  motivationalAudioUrl,
  hideMotivationParrot,
  isParrotVisible,
}) => {
  const [audio, setAudio] = useState(null);
  const [parrotAnimation, setParrotAnimation] = useState('enter');

  const parrotClassName = useMemo(
    () =>
      cn(
        'parrot-hint-overlay__parrot',
        `parrot-hint-overlay__parrot--${parrotAnimation}-animation`
      ),
    [parrotAnimation]
  );

  useEffect(() => {
    if (audio) {
      audio.addEventListener('ended', () => {
        setParrotAnimation('leave');
        setTimeout(() => {
          hideMotivationParrot();
        }, OVERLAY_HIDE_DELAY);
      });
      setTimeout(() => audio.play(), AUDIO_PLAY_DELAY);
    }
  }, [audio, hideMotivationParrot]);

  useEffect(() => {
    if (motivationalAudioUrl && isParrotVisible) {
      setAudio(new Audio(motivationalAudioUrl));
      setParrotAnimation('enter');
    } else if (motivationalAudioUrl && !isParrotVisible) {
      hideMotivationParrot();
    }
  }, [motivationalAudioUrl, isParrotVisible, hideMotivationParrot]);

  if (!motivationalAudioUrl) {
    return null;
  }

  return (
    <Zoom mobileWidth={360} mobileHeight={640} className="parrot-hint-overlay">
      <Parrot
        className={parrotClassName}
        height={!isMobile ? '800px' : '200px'}
      />
    </Zoom>
  );
};

ParrotMotivationOverlay.propTypes = {
  motivationalAudioUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hideMotivationParrot: PropTypes.func.isRequired,
  isParrotVisible: PropTypes.bool.isRequired,
};

ParrotMotivationOverlay.defaultProps = {
  motivationalAudioUrl: '',
};

const mapDispatchToProps = {
  ...actions.feedback,
};

const mapStateToProps = (state) => ({
  motivationalAudioUrl: selectors.feedback.getMotivationalAudioUrl(state),
  isParrotVisible: selectors.feedback.getParrotVisibility(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ParrotMotivationOverlay);
