import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import _startCase from 'lodash/startCase';

import { actions, selectors } from 'src/store';
import {
  notifications,
  subscriptions as subscriptionsConstants,
} from 'src/constants';
import { useIntlMessages, useMakePayment, useQuery } from 'src/hooks';
import { subscriptions as subscriptionsUtils } from 'src/utils';

import { InfoField, Localize, Title, Page } from 'src/components/common';

import './index.scss';
import ButtonNext from '../ButtonNext';
import { PromoCodeInput } from './components';

const SubscriptionSelectionReview = ({
  showNotification,
  wizardState,
  getPromoCodeDiscount,
  activatePromoCode,
  clearDiscount,
  promoCodeDiscount,
  subscriptionStatus,
  setStepsState,
}) => {
  const messages = useIntlMessages();
  const query = useQuery();
  const [promoCode, setPromoCode] = useState(
    subscriptionStatus?.promoCode || wizardState?.promoCode
  );

  const {
    subscription,
    subscriptionDurationKey,
    paymentProvider,
    paymentMethod,
    paymentLabel,
  } = wizardState;

  const makePayment = useMakePayment(
    wizardState.subscription.id,
    wizardState.subscriptionDurationKey,
    wizardState.paymentLabel,
    wizardState.subscription.key,
    wizardState.promoCode
  );

  const durationLabel = messages()[
    subscriptionsConstants.DURATION[subscriptionDurationKey]
  ];

  const totalPrice = useMemo(
    () =>
      subscriptionsUtils.getDiscountedPriceForDuration(
        subscriptionDurationKey,
        subscription.price,
        promoCodeDiscount
      ),
    [subscription.price, subscriptionDurationKey, promoCodeDiscount]
  );

  useEffect(() => {
    if (query.has('failed')) {
      showNotification(
        messages.notification.paymentFailed,
        notifications.type.ERROR,
        notifications.duration.SHORT
      );
    }
  }, [messages, paymentMethod, paymentProvider, query, showNotification]);

  useEffect(() => {
    if (promoCodeDiscount && promoCode) {
      setStepsState({
        promoCode,
      });
    }
  }, [promoCodeDiscount, promoCode, setStepsState]);

  useEffect(() => {
    if (wizardState?.promoCode && !promoCode) {
      setStepsState({
        promoCode,
      });
      clearDiscount();
    }
  }, [promoCode, setStepsState, wizardState.promoCode, clearDiscount]);

  return (
    <>
      <Page.Body className="selection-review-page">
        <Title
          className="selection-review-page__title"
          text={messages.page.subscriptionSelectionReview.title}
        />
        <InfoField
          className="selection-review-page__info-field"
          label={messages.label.selectedSubscription}
          value={
            messages({ subscriptionLabel: _startCase(subscription.label) })[
              'label.selectedSubscription.value'
            ]
          }
        />
        <InfoField
          className="selection-review-page__info-field"
          label={messages.label.selectedSubscriptionDuration}
          value={
            messages({
              duration: durationLabel,
            })['label.selectedSubscriptionDuration.value']
          }
        />
        <InfoField
          className="selection-review-page__info-field"
          label={messages.label.selectedPaymentMethod}
          value={paymentLabel}
        />
        <div className="selection-review-page__info-field-price">
          <InfoField
            className="selection-review-page__info-field"
            valueClassName="selection-review-page__total-price"
            label={messages.label.totalPrice}
            value={
              messages({
                amount: (
                  <Localize.Currency
                    id="meta.alternateCurrency"
                    value={totalPrice}
                    currencyDisplay="code"
                  />
                ),
                duration: durationLabel,
              })['label.selectedPaymentMethod.unit']
            }
          />
          <PromoCodeInput
            activatePromoCode={activatePromoCode}
            promoCode={promoCode}
            disabled={!!promoCodeDiscount}
            setPromoCode={setPromoCode}
            getPromoCodeDiscount={getPromoCodeDiscount}
            wizardState={wizardState}
          />
          {promoCodeDiscount && (
            <InfoField
              className="selection-review-page__promo-message"
              info={messages.label.promoCodeMessage}
            />
          )}
        </div>
      </Page.Body>
      <Page.Footer>
        <ButtonNext
          variant="register"
          onClick={() => makePayment(paymentProvider, paymentMethod)}
        >
          {messages.page.subscriptionSelectionReview.button}
        </ButtonNext>
      </Page.Footer>
    </>
  );
};

SubscriptionSelectionReview.propTypes = {
  showNotification: PropTypes.func.isRequired,
  wizardState: PropTypes.shape({
    paymentMethod: PropTypes.string,
    subscription: PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.string,
      label: PropTypes.string,
      price: PropTypes.number,
      alternatePrice: PropTypes.number,
    }),
    subscriptionDurationKey: PropTypes.string,
    paymentProvider: PropTypes.string,
    paymentLabel: PropTypes.string,
    promoCode: PropTypes.string,
  }).isRequired,
  setStepsState: PropTypes.func.isRequired,
  getPromoCodeDiscount: PropTypes.func.isRequired,
  activatePromoCode: PropTypes.func.isRequired,
  clearDiscount: PropTypes.func.isRequired,
  promoCodeDiscount: PropTypes.number.isRequired,
  subscriptionStatus: PropTypes.shape({
    promoCode: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  promoCodeDiscount: selectors.promoCodes.getPromoCodeDiscount(state),
  hasLoaded: selectors.promoCodes.getHasLoaded(state),
  isSubmitting: selectors.promoCodes.getIsSubmitting(state),
  subscriptionStatus: selectors.subscriptionStatus.getSubscriptionStatus(state),
});

const mapDispatchToProps = {
  ...actions.notifications,
  ...actions.subscriptions,
  ...actions.promoCodes,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(SubscriptionSelectionReview);
