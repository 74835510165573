/* eslint-disable camelcase */
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { IntlProvider } from '@kokolingo/localisation';
import { Provider } from 'react-redux';
import { Redirect, Router, Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';

import * as serviceWorker from './serviceWorker';
import { paths, gtm } from './constants';
import { translations } from './utils';

import { configureStore } from './store/utils';

import {
  AuthProvider,
  Navbar,
  Notification,
  ParrotMotivationOverlay,
  SafeRoute,
  Validators,
  ViewProvider,
  PaymentProvider,
  Topbar,
  ModalExpiredSubscription,
  StudentProvider,
  ModalDailyMap,
  WalletProvider,
  FacebookProvider,
  GoogleProvider,
  Cookiebot,
} from './components/common';

import {
  Activities,
  Auth,
  Configuration,
  ForbiddenPage,
  Help,
  Map,
  Shop,
  SpeechTherapist,
  Statistics,
  SuccessPage,
  DailyMap,
} from './components/pages';

import './theme/global.scss';

const history = new Proxy(createBrowserHistory(), {
  get(target, prop) {
    if (prop === 'transitionTo') {
      return (location) => {
        // TODO - remove react-router-redux
        // Mocking transitionTo which existed in older versions Router versions?
        target.replace(location);
      };
    }
    // eslint-disable-next-line prefer-rest-params
    return Reflect.get(...arguments);
  },
});

const store = configureStore(history);

const enhancedHistory = syncHistoryWithStore(history, store);
TagManager.initialize(gtm);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={enhancedHistory}>
        <IntlProvider
          locale={process.env.REACT_APP_LANGUAGE}
          messages={translations.getMessages()}
          defaultLocale="en"
        >
          <ViewProvider>
            <FacebookProvider>
              <GoogleProvider>
                <PaymentProvider>
                  <Switch>
                    <SafeRoute
                      exact
                      path={paths.MAGIC_LINK}
                      component={Auth.MagicLink}
                    />
                    <AuthProvider>
                      <StudentProvider>
                        <WalletProvider>
                          <ModalExpiredSubscription />
                          {process.env.REACT_APP_DAILY_MAP === 'true' && (
                            <ModalDailyMap />
                          )}
                          <Navbar />
                          <Topbar.Root />
                          <Notification />
                          <ParrotMotivationOverlay />
                          <Cookiebot />
                          <Validators.UnauthorizedValidator>
                            <Switch>
                              <SafeRoute
                                exact
                                path={paths.REGISTER_CLIENT}
                                component={Auth.ClientRegistration}
                              />
                              <SafeRoute
                                exact
                                path={paths.REGISTER_SPEECH_THERAPIST}
                                component={Auth.SpeechTherapistRegistration}
                              />
                              <SafeRoute
                                exact
                                path={paths.FORGOT_PASSWORD}
                                component={Auth.ForgotPassword}
                              />
                              <SafeRoute
                                exact
                                path={paths.RESET_PASSWORD}
                                component={Auth.ResetPassword}
                              />
                              <SafeRoute
                                exact
                                path={paths.LOGIN}
                                component={Auth.Login}
                              />
                            </Switch>
                          </Validators.UnauthorizedValidator>
                          <SafeRoute
                            exact
                            path={paths.SUCCESS_PAGE}
                            component={SuccessPage}
                          />
                          <SafeRoute
                            exact
                            path={paths.FORBIDDEN}
                            component={ForbiddenPage}
                          />
                          <Validators.AuthValidator>
                            <Validators.BaseValidator>
                              <Switch>
                                <SafeRoute
                                  exact
                                  path={paths.BASE}
                                  component={() => <Redirect to={paths.MAP} />}
                                />
                                <SafeRoute
                                  exact
                                  path={paths.SPEECH_SOUND_SELECTION}
                                  component={Configuration.SpeechSoundSelection}
                                />
                                <SafeRoute
                                  exact
                                  path={paths.STATISTICS}
                                  component={Statistics}
                                />
                                <SafeRoute
                                  exact
                                  path={paths.MAP}
                                  component={Map}
                                />
                                <SafeRoute
                                  exact
                                  path={paths.SHOP}
                                  component={Shop}
                                />
                              </Switch>
                            </Validators.BaseValidator>
                            <Validators.SubscriptionValidator>
                              <Validators.ActivitiesValidator>
                                <Switch>
                                  <SafeRoute
                                    exact
                                    path={paths.DAILY_MAP}
                                    component={DailyMap}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.ACTIVITY}
                                    component={Activities.SelectActivity}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.SUBACTIVITY}
                                    component={Activities.SelectSubactivity}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.ARTICULATION_GYMNASTICS}
                                    component={
                                      Activities.ArticulationGymnastics
                                        .Collections
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.ARTICULATION_GYMNASTICS_LEVELS}
                                    component={
                                      Activities.ArticulationGymnastics.Levels
                                        .Map
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.TREASURE_HUNT}
                                    component={Activities.TreasureHunt.Groups}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.TREASURE_HUNT_COLLECTIONS}
                                    component={
                                      Activities.TreasureHunt.Collections
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.TREASURE_HUNT_LEVELS}
                                    component={
                                      Activities.TreasureHunt.Levels.Map
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.PAINTING}
                                    component={Activities.Painting.Groups}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.PAINTING_COLLECTIONS}
                                    component={Activities.Painting.Collections}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.PAINTING_LEVELS}
                                    component={Activities.Painting.Levels.Map}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_ONE}
                                    component={
                                      Activities.Climb.Collections.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_ONE_LEVELS}
                                    component={
                                      Activities.Climb.Levels.Map.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_ONE_EXTRA}
                                    component={
                                      Activities.Climb.Collections.StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_ONE_EXTRA_LEVELS}
                                    component={
                                      Activities.Climb.Levels.Map.StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_TWO}
                                    component={
                                      Activities.Climb.Collections.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_TWO_LEVELS}
                                    component={
                                      Activities.Climb.Levels.Map.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_TWO_EXTRA}
                                    component={
                                      Activities.Climb.Collections.StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_TWO_EXTRA_LEVELS}
                                    component={
                                      Activities.Climb.Levels.Map.StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_THREE}
                                    component={
                                      Activities.Climb.Collections.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_THREE_LEVELS}
                                    component={
                                      Activities.Climb.Levels.Map.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_THREE_EXTRA}
                                    component={
                                      Activities.Climb.Collections
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.CLIMB_STEP_THREE_EXTRA_LEVELS}
                                    component={
                                      Activities.Climb.Levels.Map.StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_ONE}
                                    component={
                                      Activities.Discover.Collections.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_ONE_LEVELS}
                                    component={
                                      Activities.Discover.Levels.Map.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_ONE_EXTRA}
                                    component={
                                      Activities.Discover.Collections
                                        .StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_ONE_EXTRA_LEVELS}
                                    component={
                                      Activities.Discover.Levels.Map
                                        .StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_TWO}
                                    component={
                                      Activities.Discover.Collections.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_TWO_LEVELS}
                                    component={
                                      Activities.Discover.Levels.Map.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_TWO_EXTRA}
                                    component={
                                      Activities.Discover.Collections
                                        .StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_TWO_EXTRA_LEVELS}
                                    component={
                                      Activities.Discover.Levels.Map
                                        .StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_THREE}
                                    component={
                                      Activities.Discover.Collections.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_THREE_LEVELS}
                                    component={
                                      Activities.Discover.Levels.Map.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.DISCOVER_STEP_THREE_EXTRA}
                                    component={
                                      Activities.Discover.Collections
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.DISCOVER_STEP_THREE_EXTRA_LEVELS
                                    }
                                    component={
                                      Activities.Discover.Levels.Map
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_ONE}
                                    component={
                                      Activities.FlashCards.Collections.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_ONE_LEVELS}
                                    component={
                                      Activities.FlashCards.Levels.Map.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_ONE_EXTRA}
                                    component={
                                      Activities.FlashCards.Collections
                                        .StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.FLASH_CARDS_STEP_ONE_EXTRA_LEVELS
                                    }
                                    component={
                                      Activities.FlashCards.Levels.Map
                                        .StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_TWO}
                                    component={
                                      Activities.FlashCards.Collections.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_TWO_LEVELS}
                                    component={
                                      Activities.FlashCards.Levels.Map.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_TWO_EXTRA}
                                    component={
                                      Activities.FlashCards.Collections
                                        .StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.FLASH_CARDS_STEP_TWO_EXTRA_LEVELS
                                    }
                                    component={
                                      Activities.FlashCards.Levels.Map
                                        .StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_THREE}
                                    component={
                                      Activities.FlashCards.Collections
                                        .StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_THREE_LEVELS}
                                    component={
                                      Activities.FlashCards.Levels.Map.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FLASH_CARDS_STEP_THREE_EXTRA}
                                    component={
                                      Activities.FlashCards.Collections
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.FLASH_CARDS_STEP_THREE_EXTRA_LEVELS
                                    }
                                    component={
                                      Activities.FlashCards.Levels.Map
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_ONE}
                                    component={
                                      Activities.FindWords.Collections.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_ONE_LEVELS}
                                    component={
                                      Activities.FindWords.Levels.Map.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_ONE_EXTRA}
                                    component={
                                      Activities.FindWords.Collections
                                        .StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.FIND_WORDS_STEP_ONE_EXTRA_LEVELS
                                    }
                                    component={
                                      Activities.FindWords.Levels.Map
                                        .StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_TWO}
                                    component={
                                      Activities.FindWords.Collections.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_TWO_LEVELS}
                                    component={
                                      Activities.FindWords.Levels.Map.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_TWO_EXTRA}
                                    component={
                                      Activities.FindWords.Collections
                                        .StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.FIND_WORDS_STEP_TWO_EXTRA_LEVELS
                                    }
                                    component={
                                      Activities.FindWords.Levels.Map
                                        .StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_THREE}
                                    component={
                                      Activities.FindWords.Collections.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_THREE_LEVELS}
                                    component={
                                      Activities.FindWords.Levels.Map.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.FIND_WORDS_STEP_THREE_EXTRA}
                                    component={
                                      Activities.FindWords.Collections
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.FIND_WORDS_STEP_THREE_EXTRA_LEVELS
                                    }
                                    component={
                                      Activities.FindWords.Levels.Map
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_ONE}
                                    component={Activities.Memory.Groups.StepOne}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_ONE_COLLECTIONS}
                                    component={
                                      Activities.Memory.Collections.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_ONE_LEVELS}
                                    component={
                                      Activities.Memory.Levels.Map.StepOne
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_ONE_EXTRA}
                                    component={
                                      Activities.Memory.Groups.StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.MEMORY_STEP_ONE_EXTRA_COLLECTIONS
                                    }
                                    component={
                                      Activities.Memory.Collections.StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_ONE_EXTRA_LEVELS}
                                    component={
                                      Activities.Memory.Levels.Map.StepOneExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_TWO}
                                    component={Activities.Memory.Groups.StepTwo}
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_TWO_COLLECTIONS}
                                    component={
                                      Activities.Memory.Collections.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_TWO_LEVELS}
                                    component={
                                      Activities.Memory.Levels.Map.StepTwo
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_TWO_EXTRA}
                                    component={
                                      Activities.Memory.Groups.StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.MEMORY_STEP_TWO_EXTRA_COLLECTIONS
                                    }
                                    component={
                                      Activities.Memory.Collections.StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_TWO_EXTRA_LEVELS}
                                    component={
                                      Activities.Memory.Levels.Map.StepTwoExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_THREE}
                                    component={
                                      Activities.Memory.Groups.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_THREE_COLLECTIONS}
                                    component={
                                      Activities.Memory.Collections.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_THREE_LEVELS}
                                    component={
                                      Activities.Memory.Levels.Map.StepThree
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_THREE_EXTRA}
                                    component={
                                      Activities.Memory.Groups.StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={
                                      paths.MEMORY_STEP_THREE_EXTRA_COLLECTIONS
                                    }
                                    component={
                                      Activities.Memory.Collections
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.MEMORY_STEP_THREE_EXTRA_LEVELS}
                                    component={
                                      Activities.Memory.Levels.Map
                                        .StepThreeExtra
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.WHEEL_OF_FORTUNE}
                                    component={
                                      Activities.WheelOfFortune.Collections
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.WHEEL_OF_FORTUNE_LEVELS}
                                    component={
                                      Activities.WheelOfFortune.Levels.Map
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.REPLENISHMENT}
                                    component={
                                      Activities.Replenishment.Collections
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.REPLENISHMENT_LEVELS}
                                    component={
                                      Activities.Replenishment.Levels.Map
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.READ_TOGETHER}
                                    component={
                                      Activities.ReadTogether.Collections
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.READ_TOGETHER_LEVELS}
                                    component={
                                      Activities.ReadTogether.Levels.Map
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.READ_TOGETHER_BONUS_LEVELS}
                                    component={
                                      Activities.ReadTogether.BonusLevels.Map
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.READ_AND_THINK}
                                    component={
                                      Activities.ReadAndThink.Collections
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.READ_AND_THINK_LEVELS}
                                    component={
                                      Activities.ReadAndThink.Levels.Map
                                    }
                                  />
                                  <SafeRoute
                                    exact
                                    path={paths.READ_AND_THINK_BONUS_LEVELS}
                                    component={
                                      Activities.ReadAndThink.BonusLevels.Map
                                    }
                                  />
                                </Switch>
                              </Validators.ActivitiesValidator>
                            </Validators.SubscriptionValidator>
                            <Switch>
                              <SafeRoute
                                exact
                                path={paths.HELP}
                                component={Help}
                              />
                              <SafeRoute
                                exact
                                path={paths.SETTINGS_USER}
                                component={Configuration.UserSettings}
                              />
                              <SafeRoute
                                exact
                                path={paths.SETTINGS_COUPON}
                                component={Configuration.CouponSettings}
                              />
                              <SafeRoute
                                exact
                                path={paths.SETTINGS_DAILY_MAP}
                                component={Configuration.DailyMapSettings}
                              />
                              <SafeRoute
                                exact
                                path={paths.SETTINGS_APP}
                                component={Configuration.AppSettings}
                              />
                              <SafeRoute
                                exact
                                path={paths.SETTINGS_STUDENT}
                                component={Configuration.StudentSettings}
                              />
                              <SafeRoute
                                exact
                                path={paths.LICENSE}
                                component={Configuration.License}
                              />
                              <SafeRoute
                                exact
                                path={paths.SPEECH_THERAPIST}
                                component={SpeechTherapist.Home}
                              />
                            </Switch>
                            <Validators.SpeechTherapistValidator>
                              <Switch>
                                <SafeRoute
                                  exact
                                  path={paths.SUBSCRIPTION_STATUS}
                                  component={Configuration.SubscriptionStatus}
                                />
                                <SafeRoute
                                  exact
                                  path={paths.SUBSCRIPTIONS}
                                  component={Configuration.Subscriptions}
                                />
                                <SafeRoute
                                  exact
                                  path={paths.SUBSCRIPTION_PAYMENT}
                                  component={Configuration.Payment}
                                />
                                <SafeRoute
                                  exact
                                  path={paths.PAYMENT_ROUTER}
                                  component={Configuration.PaymentRouter}
                                />
                              </Switch>
                            </Validators.SpeechTherapistValidator>
                          </Validators.AuthValidator>
                        </WalletProvider>
                      </StudentProvider>
                    </AuthProvider>
                  </Switch>
                </PaymentProvider>
              </GoogleProvider>
            </FacebookProvider>
          </ViewProvider>
        </IntlProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
