import { useCallback } from 'react';

import { useHistory, useLocation } from 'react-router';

import { paths } from 'src/constants';

export default ({
  pathLeftovers = [],
  isSpeechTherapist = false,
  reload = false,
}) => {
  const history = useHistory();
  const location = useLocation();

  return useCallback(() => {
    const locationArray = location.pathname.split('/');

    // @TODO delete if in vain
    if (reload) {
      history.go(0);
    }

    if (
      new URLSearchParams(window.location.search).get('is-speech-therapist')
    ) {
      history.push(paths.SPEECH_THERAPIST);
      return;
    }

    if (locationArray[locationArray.length - 1] === 'bonus-levels') {
      locationArray.pop();
    }

    if (isSpeechTherapist && locationArray[1] === 'statistics') {
      history.goBack();
      return;
    }

    locationArray.pop();

    if (pathLeftovers.includes(locationArray[locationArray.length - 1])) {
      locationArray.pop();
    }

    if (
      isSpeechTherapist &&
      locationArray.length === 2 &&
      locationArray[1] === 'settings'
    ) {
      history.push('/speech-therapist');
      return;
    }

    history.push(locationArray.join('/'));
  }, [history, location.pathname, pathLeftovers, isSpeechTherapist, reload]);
};
