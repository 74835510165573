import React from 'react';
import _endsWith from 'lodash/endsWith';

import { actions, selectors } from 'src/store';
import { notifications, paths } from 'src/constants';
import { Localize } from 'src/components/common';

// clear user if a speech sound change header is returned
// to get updated speechSound
export default () => (store) => (next) => (action) => {
  const student = selectors.authentication.getStudent(store.getState());
  const requestSpeechSound = Number(
    action.result?.headers?.['speech-sound-id']
  );

  if (
    window.location.pathname !== paths.BASE &&
    _endsWith(action.type, '_SUCCESS') &&
    requestSpeechSound &&
    requestSpeechSound !== student.SpeechSoundId
  ) {
    store.dispatch(
      actions.authentication.setStudentSpeechSound(requestSpeechSound)
    );
    store.dispatch(
      actions.notifications.showNotification(
        <Localize id="notification.speechSoundChange" />,
        notifications.type.INFO,
        notifications.duration.LONG
      )
    );
  }

  return next(action);
};
