import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { feedback, video } from 'src/constants';
import { useAudio, useIntlMessages } from 'src/hooks';

import {
  Container,
  FeedbackContainer,
  FeedbackPanel,
  FeedbackPreview,
  GameCoins,
  Localize,
  Navbar,
  PageLoader,
  Topbar,
} from 'src/components/common';

import LevelCard from './components/LevelCard';

import './index.scss';

const ArticulationGymnasticsContainer = ({
  currency,
  handleLevelAnswer,
  handleCollectionEnd,
  hasLoaded,
  items,
  navigation,
}) => {
  const messages = useIntlMessages();

  const [collectedCoins, setCollectedCoins] = useState(0);
  const [displayModal, setDisplayModal] = useState(false);
  const [level, setLevel] = useState(0);
  const [playAudio, isAudioPlaying] = useAudio();

  const levelItem = items[level] || {};

  const handleNextLevel = useCallback(
    (isCorrect) => {
      if (level < items.length - 1) {
        setLevel((prevLevel) => prevLevel + 1);
      }

      handleLevelAnswer(levelItem.id, isCorrect, currency?.id);
      setCollectedCoins((prevCollectedCoins) => prevCollectedCoins + 1);
      setDisplayModal(false);
    },
    [level, items.length, handleLevelAnswer, levelItem.id, currency]
  );

  const handleParentFeedback = useCallback(
    (isCorrect) => {
      if (isAudioPlaying) {
        return;
      }

      if (!displayModal) {
        setDisplayModal(true);
        return;
      }

      playAudio(feedback.COIN_COLLECT_SOUND_URL, () =>
        handleNextLevel(isCorrect)
      );
    },
    [displayModal, handleNextLevel, isAudioPlaying, playAudio]
  );

  useEffect(() => {
    if (items.length > 0 && collectedCoins >= items.length && !displayModal) {
      playAudio(feedback.LEVEL_END_SOUND_URL, handleCollectionEnd);
    }
    // playAudio in deps causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedCoins, displayModal, items.length, handleCollectionEnd]);

  if (!hasLoaded) {
    return <PageLoader />;
  }

  return (
    <PageLoader isFadingOut>
      {navigation}
      <Navbar.GameNavbar
        currency={currency}
        title={messages.activity.articulationGymnastics}
        videoName={video.NAME.ARTICULATION_GYMNASTICS}
      />
      <Topbar>
        <GameCoins
          coinSize="small"
          type={currency.key}
          totalCoinsCount={items.length}
          collectedCoinsCount={collectedCoins}
        />
      </Topbar>
      <Container className="articulation-gymnastics-levels-container">
        <FeedbackContainer isVisible={displayModal}>
          <FeedbackPreview.Video src={levelItem.videoUrl} />
          <FeedbackPanel.Grades
            onClick={handleParentFeedback}
            label={<Localize id="label.videoFeedbackQuestion" />}
          />
        </FeedbackContainer>

        <div className="articulation-gymnastics-levels-container__body">
          <LevelCard
            level={levelItem}
            handleVideoEnded={handleParentFeedback}
          />
        </div>
      </Container>
    </PageLoader>
  );
};

ArticulationGymnasticsContainer.propTypes = {
  currency: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    imageUrl: PropTypes.string,
    placeholderImageUrl: PropTypes.string,
  }),
  handleCollectionEnd: PropTypes.func.isRequired,
  handleLevelAnswer: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  navigation: PropTypes.node,
};

ArticulationGymnasticsContainer.defaultProps = {
  currency: null,
  items: [],
  navigation: null,
};

export default memo(ArticulationGymnasticsContainer);
