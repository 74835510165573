import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useIntlMessages } from 'src/hooks';

import { Button, Localize } from 'src/components/common';

import './index.scss';

const SubscriptionCard = ({
  className,
  isHighlighted,
  onClick,
  subscription,
}) => {
  const messages = useIntlMessages();

  const { description = '', fullPrice = 0, label, price } = subscription;

  const containerClassNames = cn('subscription-card-container', className);

  return (
    <div className={containerClassNames}>
      {isHighlighted && (
        <div className="ribbon ribbon-top-right">
          <span />
        </div>
      )}
      <div className="subscription-card-container__title">{label}</div>
      <div className="subscription-card-container__description">
        {description}
      </div>
      <div className="subscription-card-container__prices">
        <div className="subscription-card-container__price-container">
          <div className="subscription-card-container__price-container__label">
            {messages.page.subscriptionSelection.card.regularPrice}
          </div>
          {fullPrice && (
            <div>
              <span className="subscription-card-container__price-container__price">
                <Localize.Currency
                  id="meta.alternateCurrency"
                  value={fullPrice}
                  locale="de"
                />
              </span>
            </div>
          )}
          <div className="subscription-card-container__price-container__currency">
            {messages.page.subscriptionSelection.card.currencyMonthly}
          </div>
        </div>
        <div className="subscription-card-container__price-container">
          <div className="subscription-card-container__price-container__label--highlighted">
            {messages.page.subscriptionSelection.card.discountedPrice}
            <span className="subscription-card-container__price-container__asterisk">
              *
            </span>
          </div>
          {price && (
            <div>
              <span className="subscription-card-container__price-container__price--highlighted">
                <Localize.Currency
                  id="meta.alternateCurrency"
                  value={price}
                  locale="de"
                />
              </span>
            </div>
          )}
          <div className="subscription-card-container__price-container__currency">
            {messages.page.subscriptionSelection.card.currencyMonthly}
          </div>
        </div>
      </div>
      <Button
        className="subscription-card-container__button"
        label={messages.page.subscriptionSelection.card.button}
        onClick={onClick}
      />
    </div>
  );
};

SubscriptionCard.propTypes = {
  className: PropTypes.string,
  subscription: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    fullPrice: PropTypes.number,
    validTo: PropTypes.string,
    alternatePrice: PropTypes.number,
    alternateFullPrice: PropTypes.number,
  }).isRequired,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SubscriptionCard.defaultProps = {
  className: '',
  isHighlighted: false,
};

export default SubscriptionCard;
